import React, { useEffect, useState } from "react";

function ProductTypeCard({ label, value, onClick, selected }) {
  return (
    <div
      onClick={() => onClick(value)}
      className={`p-4 rounded-lg border w-full flex items-center justify-between cursor-pointer border-[2px] hover:border-[#318EC9] ${
        selected ? "border-[#318EC9]" : ""
      }`}
    >
      <div className="font-[500] text-[17px] max-md:text-[15px] max-sm:text-[13px] text-[#344054]">
        {label}
      </div>
      <div className="flex items-center">
        <input
          type="checkbox"
          checked={selected}
          style={{ height: "1.5em", width: "1.5em" }}
        />
      </div>
    </div>
  );
}

export default ProductTypeCard;
