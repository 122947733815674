import { getNetworkErrorMessage } from "../helpers/network";
import axiosInstance from "../utils/axiosInstance";


export const registerMaker = async (data) => {
    return axiosInstance(`/makers`, "POST", data).then(response => {
        return response;
    }).catch(err => {
        getNetworkErrorMessage(err)
    })
}