import CustomIconWithRoundedBg from "./CustomIconWithRoundedBg";
import { getDateTime } from "../helpers/dateTime";
import { capitalizeFirstLetter } from "../helpers/strings";

function TimelineItem({
  children,
  color = "text-textGrey",
  bg = "#FF9D21",
  status,
  description,
  date,
  showLine,
  className,
}) {
  return (
    <div className="flex items- gap-3">
      <div>
        <CustomIconWithRoundedBg
          className={`${!showLine ? "text-white" : ""} ${className}`}
          bg={bg}
          children={children}
        />
        {showLine && (
          <div className="w-[1.5px] h-[3em] mx-auto bg-blue-900"></div>
        )}
      </div>
      <div className={color}>
        <div className="font-[500] text-[14px] text-black">
          {capitalizeFirstLetter(status)}
        </div>
        <div>{description}</div>
        <div>{getDateTime(date, "DD/MM/YYYY HH:mm A")}</div>
      </div>
    </div>
  );
}

export default TimelineItem;
