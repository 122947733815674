import { useMutation, useQueryClient } from '@tanstack/react-query'
import { VENDOR_ITEMS_QUERY_KEY } from '../constants/queryKeys';
import { updateOrder } from '../services/order-services';

function useUpdateOrder() {
    const queryClient = useQueryClient();
    const { mutateAsync: updateOrderDetails, isLoading, error, data } = useMutation({
        mutationFn: (orderDetails) => updateOrder(orderDetails),
        onSuccess: (data) => {
            queryClient.invalidateQueries([VENDOR_ITEMS_QUERY_KEY])
        },
        onError: (error) => {
            // return error
        }
    })
    return {
        updateOrderDetails, isLoading, error, data
    }
}

export default useUpdateOrder