import React from "react";
import { useGoogleLogin } from "@react-oauth/google";
import RoundedMdButton from "../../commons/RoundedMdButton";
import { FcGoogle } from "react-icons/fc";

const GoogleLoginAuth = ({ disabled, handleLogin }) => {
  const login = useGoogleLogin({
    flow: "auth-code",
    onSuccess: (codeResponse) => {
      const code = codeResponse?.code;
      handleLogin({code});
    },
    onError: () => {
      console.log("Login Failed");
    },
  });
  return (
    <RoundedMdButton
      label="Continue with Google"
      className="bg-white text-black border w-full"
      disabled={disabled}
      onClick={login}
    >
      <FcGoogle className="text-[20px]" />
    </RoundedMdButton>
  );
};

export default GoogleLoginAuth;
