import React, { useState } from "react";

import { Formik } from "formik";
import { Form } from "formik";
import HeadingAndDetails from "../HeadingAndDetails";
import CustomInputField from "../../../commons/CustomInputField";
import { validEmail } from "../../../helpers/validator";
import useStates from "../../../hooks/useStates";
import CustomSelectField from "./../../../commons/CustomSelectField";
import useTowns from "./../../../hooks/useTowns";

function UnregisteredBusinessDetails({
  unregisteredBusinessDetails,
  setUnregisteredBusinessDetails,
}) {
  const { states: stateList } = useStates();
  const [state, setState] = useState("");
  const [region, setRegion] = useState("");
  const { towns } = useTowns(state);

  return (
    <div className="mt-6">
      <HeadingAndDetails
        title={"Business Details"}
        description={"Kindly complete the form below"}
      />
      <div className="mt-2"></div>
      <Formik
        enableReinitialize
        initialValues={{
          businessEmail: unregisteredBusinessDetails?.businessEmail ?? "",
          businessName: unregisteredBusinessDetails?.businessName ?? "",
          registeredAddress:
            unregisteredBusinessDetails?.registeredAddress ?? "",
          state: unregisteredBusinessDetails?.state ?? "",
          region: unregisteredBusinessDetails?.region ?? "",
          yearsOfOperation: unregisteredBusinessDetails?.yearsOfOperation ?? "",
        }}
        validate={(values) => {
          const errors = {};

          if (!values.businessName) {
            errors.businessName = "Business name is required";
          }
          if (!values.businessEmail) {
            errors.businessEmail = "Business email is required";
          } else if (!validEmail(values.businessEmail)) {
            errors.businessEmail = "Invalid email address";
          }

          if (!values.address) {
            errors.address = "Operation address is required";
          }

          if (!values.yearsOfOperation) {
            errors.yearsOfOperation = "Years of operation is required";
          }
          if (!values.state || values.state == "") {
            setState("");
            errors.state = "State is required";
          } else {
            setState(values.state);
          }
          if (!values.region) {
            errors.region = "Region is required";
          } else {
            setRegion(values.region);
          }

          if (Object.keys(errors).length === 0) {
            setUnregisteredBusinessDetails(values);
          }

          console.log("errors", errors);
          return errors;
        }}
      >
        {({ isSubmitting, setFieldValue, setFieldError }) => (
          <Form>
            <div className="flex max-md:flex-col max-md:gap-0 items-center gap-4">
              <div className="w-full">
                <CustomInputField
                  name={"businessName"}
                  required={true}
                  placeholder="Enter business name"
                  label={"Business Name"}
                />
              </div>
              <div className="w-full">
                <CustomInputField
                  name={"businessEmail"}
                  required={true}
                  placeholder="Enter business email"
                  label={"Business Email"}
                />
              </div>
              <div className="w-full">
                <CustomInputField
                  name={"address"}
                  required={true}
                  placeholder="Enter registered address"
                  label={"Operation Address"}
                />
              </div>
            </div>
            <div className="flex max-md:flex-col max-md:gap-0 items-center gap-4">
              <div className="w-full">
                <CustomSelectField
                  name={"state"}
                  required={true}
                  placeholder="Select state"
                  type="text"
                  label={"State"}
                  valueKey={"state_code"}
                  keyValue={"name"}
                  list={stateList?.data ?? []}
                  onChange={(value) => setFieldValue("state", value)}
                  setFieldError={setFieldError}
                />
              </div>
              <div className="w-full">
                {!state && (
                  <CustomInputField
                    readOnly={true}
                    placeholder="Select region"
                    name="region"
                    type="text"
                    label={"Region"}
                  />
                )}
                {state && (
                  <CustomSelectField
                    name={"region"}
                    required={true}
                    placeholder="Select region"
                    type="text"
                    label={"Region"}
                    valueKey={"name"}
                    keyValue={"name"}
                    list={!towns?.data?.message ? towns?.data : []}
                    onChange={(value) => setFieldValue("region", value)}
                    setFieldError={setFieldError}
                    disabled={state === ""}
                  />
                )}
              </div>

              <div className="w-full">
                <CustomInputField
                  name={"yearsOfOperation"}
                  required={true}
                  placeholder="Enter years of operation"
                  type="number"
                  label={"Years of Operation"}
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default UnregisteredBusinessDetails;
