import React from "react";
import { GoDotFill } from "react-icons/go";
import { MdEdit } from "react-icons/md";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";

function StyledSpan({ bgColor, textColor, text, editable = false, onClick }) {
  return (
    <span
      className={`${bgColor} ${
        editable ? "cursor-pointer" : ""
      } flex items-center justify-between gap-1 bg-[#EFF8FF] px-1 rounded-lg`}
      onClick={editable ? onClick : () => {}}
    >
      <div className="flex items-center gap-2 max-md:gap-1">
        <GoDotFill className={textColor} />
        <span
          className={`${textColor} text-[0.75em] max-md:text-[0.5em] whitespace-nowrap`}
        >
          {text}
        </span>
      </div>
      {editable && (
        <div>
          <MdOutlineKeyboardArrowDown className={textColor} />
        </div>
      )}
    </span>
  );
}

export default StyledSpan;
