import { useMutation, useQueryClient } from '@tanstack/react-query'
import { updateVendor } from '../services/vendors-services';

function useUpdateVendor() {
    const queryClient = useQueryClient();
    const { mutateAsync: updateVendorLogo, isLoading, error, data } = useMutation({
        mutationFn: (logoDetails) => updateVendor(logoDetails),
        onSuccess: (data) => {
            
        },
        onError: (error) => {
            // return error
        }
    })
    return {
        updateVendorLogo, isLoading, error, data
    }
}

export default useUpdateVendor