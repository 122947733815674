import { useQuery } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom';
import { TOWNS_QUERY_KEY } from '../constants/queryKeys';
import { getTownList } from '../services/states-service';

function useTowns(stateCode) {
    const navigate = useNavigate()
    const { data: towns, isLoading, error } = useQuery({
        queryKey: [TOWNS_QUERY_KEY, stateCode],
        queryFn: async () => {
            const result = await getTownList({ stateCode })
            return result?.data?.data
        },
        onError: (error) => {
            // return error
        },
        enabled: stateCode != "",
    })
    return {
        towns, isLoading, error
    }
}

export default useTowns