import React from "react";

function AccountLinkItemMini({
  children,
  label,
  className = "hover:text-[#FF9D21]",
  link,
  onClick,
}) {
  return (
    <>
      {!onClick && (
        <a
          href={link}
          className={`flex gap-3 mb-5 font-[500] text-[12px] items-center cursor-pointer ${className}`}
        >
          <div className="font-[600]">{children}</div>
          <div>{label}</div>
        </a>
      )}
      {onClick && (
        <span
          onClick={onClick}
          className={`flex gap-3 mb-5 font-[500] text-[12px] items-center cursor-pointer ${className}`}
        >
          <div className="font-[600]">{children}</div>
          <div>{label}</div>
        </span>
      )}
    </>
  );
}

export default AccountLinkItemMini;
