import React from "react";
import { GoPlus } from "react-icons/go";
import PageTitle from "../../commons/PageTitle";
import NavButton from "./NavButton";
import { useNavigate } from "react-router-dom";

function AllNavBars({
  pageTitle = "Welcome",
  user,
  pageDescription,
  selectedIndex,
}) {
  const navigate = useNavigate();
  const goTo = (link) => {
    navigate(link);
  };
  return (
    <div className="flex items-center max-sm:flex-col max-sm:items-start gap-8 max-md:gap-4 justify-between my-6">
      <div className="text-left">
        <PageTitle title={pageTitle + ", " + user?.firstName} />
        <p className="font-[500] -mt-[1.5em] max-md:-mt-[2em] text-[14px] max-md:text-[12px] text-[#667185]">
          {pageDescription}
        </p>
      </div>
      <div className="flex items-center gap-2">
        <NavButton
          label="Products"
          selected={selectedIndex == 1}
          onClick={() => {
            goTo("/dashboard/products");
          }}
        />
        <NavButton
          label="Orders"
          selected={selectedIndex == 2}
          onClick={() => {
            goTo("/dashboard/orders");
          }}
        />
        <NavButton
          label="Payments"
          selected={selectedIndex == 3}
          onClick={() => {
            goTo("/dashboard/payments");
          }}
        />
        <NavButton
          label="Add Products"
          className={"text-white bg-[#FF9D21]"}
          onClick={() => {
            goTo("/dashboard/add-product");
          }}
        >
          <GoPlus />
        </NavButton>
      </div>
    </div>
  );
}

export default AllNavBars;
