import React from "react";
import LinkItem from "./LinkItem";
import useUser from "./../hooks/useUser";

function BottomHeader() {
  const { user } = useUser();

  return (
    <div className="bg-black px-10 flex items-center justify-center relative mt-[6em]">
      {user && user?.verified && (
        <div className="flex gap-10 max-lg:gap-8 max-md:gap-4">
          <a href="/dashboard/products">
            <LinkItem label={"Products"} link="/dashboard/products" />
          </a>
          <a href="/dashboard/orders">
            <LinkItem label={"Orders"} link="/dashboard/orders" />
          </a>
          <a href="/dashboard/payments">
            <LinkItem label={"Payments"} link="/dashboard/payments" />
          </a>
          <a href="/dashboard/add-product">
            <LinkItem label={"Add Product"} link="/dashboard/add-product" />
          </a>
        </div>
      )}
      {!user && (
        <div className="flex gap-10">
          <a href="/onboarding">
            <LinkItem label={"Onboarding"} />
          </a>
        </div>
      )}
      {user && !user?.verified && (
        <div className="flex gap-10">
          <a href="/complete-onboarding">
            <LinkItem label={"Complete Onboarding"} />
          </a>
        </div>
      )}
    </div>
  );
}

export default BottomHeader;
