import React, { useState, useRef } from "react";
import HeadingAndDetails from "./../HeadingAndDetails";
import { Formik } from "formik";
import { Form } from "formik";
import CustomInputField from "../../../commons/CustomInputField";
import { validEmail } from "../../../helpers/validator";
import {
  PNG_FILE_TYPE,
  JPG_FILE_TYPE,
  JPEG_FILE_TYPE,
  WEBP_FILE_TYPE,
  DOCX_FILE_TYPE,
  PDF_FILE_TYPE,
} from "../../../constants/texts";
import CustomSelectField from "../../../commons/CustomSelectField";
import { MEANS_OF_ID } from "../../../constants/data";
import useCountries from "./../../../hooks/useCountries";
import ConfirmationModal from "../../../commons/modals/ConfirmationModal";
import RoundedMdButton from "../../../commons/RoundedMdButton";
import { getFileTypesFriendly } from "../../../helpers/fileUpload";

const fileTypes = [
  PNG_FILE_TYPE,
  JPG_FILE_TYPE,
  JPEG_FILE_TYPE,
  WEBP_FILE_TYPE,
  PDF_FILE_TYPE,
  DOCX_FILE_TYPE,
];

function OwnerDetails({ ownerDetails, setOwnerDetails }) {
  const [fileInput, setFileInput] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const idFileRef = useRef();

  const { countries } = useCountries();

  return (
    <div className="mt-6">
      <HeadingAndDetails
        title={"Owner Details"}
        description={"Kindly complete the form below"}
      />
      <div className="mt-2"></div>
      <Formik
        enableReinitialize
        initialValues={{
          email: ownerDetails?.email ?? "",
          phoneNumber: ownerDetails?.phoneNumber ?? "",
          firstName: ownerDetails?.firstName ?? "",
          lastName: ownerDetails?.lastName ?? "",
          middleName: ownerDetails?.middleName ?? "",
          nationality: ownerDetails?.nationality ?? "",
          meansOfIdentification: "",
          meansOfIdentificationFile: ownerDetails?.idFilePath ?? "",
        }}
        validate={(values) => {
          const errors = {};

          if (values.meansOfIdentificationFile === null) {
            setFileInput("");
            setOpenModal(true);
          }

          if (!values.firstName) {
            errors.firstName = "First name is required";
          }

          if (!values.lastName) {
            errors.lastName = "Last name is required";
          }

          if (!values.nationality) {
            errors.nationality = "Nationality is required";
          }

          if (!values.meansOfIdentification) {
            errors.meansOfIdentification =
              "Means of identification is required";
          }

          if (!values.meansOfIdentificationFile) {
            errors.meansOfIdentificationFile =
              "Means of identification file is required";
          }

          if (Object.keys(errors).length == 0) {
            setOwnerDetails(values);
          }

          return errors;
        }}
      >
        {({ isSubmitting, setFieldError, setFieldValue }) => (
          <Form>
            <div className="flex max-md:flex-col max-md:gap-0 items-center gap-4">
              <div className="w-full">
                <CustomInputField
                  name={"firstName"}
                  required={true}
                  placeholder="Enter first name"
                  label={"First Name"}
                />
              </div>
              <div className="w-full">
                <CustomInputField
                  name={"lastName"}
                  required={true}
                  placeholder="Enter last name"
                  label={"Last Name"}
                />
              </div>
              <div className="w-full">
                <CustomInputField
                  name={"middleName"}
                  required={false}
                  placeholder="Enter middle name"
                  label={"Middle Name"}
                />
              </div>
            </div>
            <div className="flex max-md:flex-col max-md:gap-0 items-center gap-4">
              <div className="w-full">
                <CustomSelectField
                  name={"nationality"}
                  list={countries?.data ?? []}
                  required={true}
                  placeholder="Select nationality"
                  label={"Nationality"}
                  onChange={(value) => setFieldValue("nationality", value)}
                />
              </div>
              <div className="w-full">
                <CustomSelectField
                  name={"meansOfIdentification"}
                  required={true}
                  placeholder="Select means of identification"
                  label={"Means of Identification"}
                  list={MEANS_OF_ID}
                  onChange={(value) =>
                    setFieldValue("meansOfIdentification", value)
                  }
                />
              </div>
              <div className="w-full">
                <CustomInputField
                  name={"meansOfIdentificationFile"}
                  required={true}
                  type="file"
                  placeholder=""
                  label={"Attach Means of Identification"}
                  fileTypes={fileTypes}
                  setFieldValue={setFieldValue}
                  setFieldError={setFieldError}
                  inputRef={idFileRef}
                  fileCallback={setFileInput}
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>

      <ConfirmationModal
        isOpen={openModal && fileInput != ""}
        type={"warning"}
        title={"File upload error"}
        description={""}
        subText={
          "Invalid file type. Acceptable types (" +
          getFileTypesFriendly(fileTypes) +
          ")"
        }
        onClose={() => {
          setOpenModal(false);
        }}
      >
        <div className="my-5">
          <RoundedMdButton
            label={"OK"}
            onClick={() => {
              setOpenModal(false);
            }}
          />
        </div>
      </ConfirmationModal>
    </div>
  );
}

export default OwnerDetails;
