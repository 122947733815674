import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom';
import { USER_QUERY_KEY } from '../constants/queryKeys';
import { update, updateVendor } from "../services/auth-service";

function useUpdateVendorProfile() {
    const navigate = useNavigate()
    const queryClient = useQueryClient();
    const { mutateAsync: updateVendorProfile, isLoading, error, data } = useMutation({
        mutationFn: (updateData) => updateVendor(updateData),
        onSuccess: (data) => {
            queryClient.invalidateQueries([USER_QUERY_KEY])
            navigate("/my-account")
        },
        onError: (error) => {
            // return error
        }
    })
    return {
        updateVendorProfile, isLoading, error, data
    }
}

export default useUpdateVendorProfile