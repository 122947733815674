import React, { useState } from "react";
import useAddToSubscription from "../hooks/useAddToSubscription";
import { product_categories } from "../mock_data/product_categories";
import CustomMiniButton from "./CustomMiniButton";
import FooterMenuItem from "./FooterMenuItem";
import SocialLinks from "./SocialLinks";
import { validEmail } from "./../helpers/validator";
import CustomSnackBar from "./CustomSnackBar";

const customerServices = {
  title: "Customer Service",
  links: [
    {
      id: 1,
      label: "Support Center",
      url: `${process.env.REACT_APP_STORE_FRONT_URL}/contact-us`,
    },
    {
      id: 2,
      label: "Shipping & Returns",
      url: `${process.env.REACT_APP_STORE_FRONT_URL}/shipping-and-returns`,
    },
    {
      id: 3,
      label: "Privacy Policy",
      url: `${process.env.REACT_APP_STORE_FRONT_URL}/privacy-policy`,
    },
    {
      id: 4,
      label: "FAQ",
      url: `${process.env.REACT_APP_STORE_FRONT_URL}/frequently-asked-questions`,
    },
  ],
};

const companyDetails = {
  title: "Company",
  links: [
    {
      id: 1,
      label: "Our Story",
      url: `${process.env.REACT_APP_STORE_FRONT_URL}/about-wudify`,
    },
    {
      id: 2,
      label: "Bespoke Service",
      url: `${process.env.REACT_APP_STORE_FRONT_URL}/bespoke-services`,
    },
    {
      id: 3,
      label: "Decor Tips",
      url: `${process.env.REACT_APP_STORE_FRONT_URL}/inspiration/decor-tips`,
    },
    {
      id: 4,
      label: "Furniture Guide",
      url: `${process.env.REACT_APP_STORE_FRONT_URL}/inspiration/furniture-guides`,
    },
    {
      id: 5,
      label: "Partner with us",
      url: `${process.env.REACT_APP_STORE_FRONT_URL}/contact-us`,
    },
  ],
};

const productCategories = {
  title: "Shop",
  links: [
    ...product_categories.map((category, index) => {
      return {
        id: index + 1,
        label: category.name,
        url:
          `${process.env.REACT_APP_STORE_FRONT_URL}/categories/` +
          category.slug,
      };
    }),
    {
      id: 5000,
      label: "Sales",
      url: `${process.env.REACT_APP_STORE_FRONT_URL}/sales`,
    },
  ],
};

function Footer() {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [adding, setAdding] = useState(false);

  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [isError, setIsError] = useState(false);

  const { addToSubscriptionList: addToSub } = useAddToSubscription();

  const handleEmailInputChange = (e) => {
    setError("");
    setEmail(e.target.value);
  };

  const addToSubscriptionList = () => {
    if (!email) {
      setError("Email is required");
    } else if (!validEmail(email)) {
      setError("Please enter a valid email");
    } else {
      setAdding(true);
      addToSub({ email })
        .then((res) => {
          if (res.data.statusCode === 201) {
            setIsError(false);
            setSnackBarMessage(res.data.message);
          } else {
            setIsError(true);
            setSnackBarMessage("An error occurred. Please try again later");
          }
        })
        .catch((err) => {
          setIsError(true);
          if (err?.message.includes("unique")) {
            setSnackBarMessage("You have already subscribed");
          } else {
            setSnackBarMessage(err?.message);
          }
        })
        .finally(() => {
          setOpenSnackBar(true);
          setAdding(false);
        });
    }
  };

  return (
    <div className="text-white bg-black py-10 w-full relative bottom-0">
      <div className="w-[95%] mx-auto flex max-lg:flex-col gap-10 justify-around">
        <div className="w-2/5 max-lg:w-full flex flex-col gap-4 items-start">
          {/* Logo */}
          <img
            src="/assets/wudify_logo_footer.png"
            alt="Wudify logo"
            loading="lazy"
          />

          {/* Mail subscription */}
          <p className="w-[80%] text-left">
            Be the first to receive all the recent updates, articles, and
            valuable materials.
          </p>
          {error && <p className="text-red-500">{error}</p>}
          <div className="flex items-end gap-1 ">
            <input
              placeholder="Email address"
              style={{ textDecoration: "strike-through" }}
              className="py-3.5 rounded-lg w-2/3 px-4 text-black outline-none"
              value={email}
              onChange={handleEmailInputChange}
            />
            <div className="w-1/3">
              <CustomMiniButton
                label={adding ? "..." : "Subscribe"}
                className="py-3 px-7 bg-[#EB9120]"
                onClick={addToSubscriptionList}
                disabled={adding}
              ></CustomMiniButton>
            </div>
          </div>

          {/* Icons */}
          <SocialLinks />
        </div>

        <div className="w-3/5 max-lg:w-full flex gap-10 max-lg:gap-6 max-md:gap-4 justify-between">
          {/* CATEGORIES */}
          <FooterMenuItem
            title={productCategories.title}
            subLinks={productCategories.links}
          />

          {/* CUSTOMER SERVICES */}
          <FooterMenuItem
            title={customerServices.title}
            subLinks={customerServices.links}
          />

          {/* COMPANY */}
          <FooterMenuItem
            title={companyDetails.title}
            subLinks={companyDetails.links}
          />
        </div>
      </div>

      <CustomSnackBar
        isOpen={openSnackBar}
        onClose={() => setOpenSnackBar(false)}
        message={snackBarMessage}
        isError={isError}
      />

      {/* FOOTER LOWER PART */}
      <hr className="w-[95%] mx-auto my-12 text-gray-900" />
      <div className="flex flex-wrap gap-2 justify-between mt-12 mb-8 mx-auto w-[95%] px-4">
        <div className="flex gap-6">
          <p>Terms of Service</p>
          <p>
            <a href={`${process.env.REACT_APP_STORE_FRONT_URL}/privacy-policy`}>
              Privacy Policy
            </a>
          </p>
          <p>Security</p>
          <p>Sitemap</p>
        </div>
        <div>&copy; Wudify 2023. All rights reserved. </div>
      </div>
    </div>
  );
}

export default Footer;
