import React from "react";
import CurrencyFormat from "react-currency-format";

function CustomPriceDisplay({
  price,
  prefix = "₦",
  displayType = "text",
  text = "",
}) {
  return (
    <div>
      <span>{text}</span>
      <CurrencyFormat
        value={price}
        displayType={displayType}
        thousandSeparator={true}
        prefix={prefix}
        decimalScale={2}
      />
    </div>
  );
}

export default CustomPriceDisplay;
