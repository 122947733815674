

export const validEmail = (email) => {
    if (!email || email == "") {
        return false;
    }

    if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
        return true;
    }


    return false;
}

export const validFBUrl = (string) => {
    if (!string || string == "") {
        return false;
    }

    if (/(?:(?:http|https):\/\/)?(?:www.)?facebook.com\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[?\w\-]*\/)?(?:profile.php\?id=(?=\d.*))?([\w\-]*)?/i.test(string)) {
        return true;
    }


    return false;

}

export const validTwitterUrl = (string) => {
    if (!string || string == "") {
        return false;
    }

    if (/(?:http:\/\/)?(?:www\.)?twitter\.com\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[\w\-]*\/)*([\w\-]*)/i.test(string)) {
        return true;
    }


    return false;

}

export const validLinkedinUrl = (string) => {
    if (!string || string == "") {
        return false;
    }

    if (/((https?:\/\/)?((www|\w\w)\.)?linkedin\.com\/)((([\w]{2,3})?)|([^\/]+\/(([\w|\d-&#?=])+\/?){1,}))$/i.test(string)) {
        return true;
    }


    return false;

}

export const validUrl = (string) => {
    if (!string || string == "") {
        return false;
    }

    if (/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g.test(string)) {
        return true;
    }


    return false;

}


export const validPhoneNumber = (phoneNumber) => {
    // This regex pattern matches phone numbers with or without country code and various separators
    const phoneRegex = /^(\+?\d{1,4}[\s-]?)?(\(?\d{3}\)?[\s-]?)?\d{3}[\s-]?\d{4}$/;
    return phoneRegex.test(phoneNumber);
}