export const product_categories = [
  {
    id: 1,
    name: "Furniture",
    slug: "furniture",
  },
  {
    id: 2,
    name: "Decor & More",
    slug: "decor_and_more",
  },
  {
    id: 3,
    name: "Lighting",
    slug: "lighting",
  },
];

export const products = [
  {
    id: 1,
    name: "Tayen Side Table",
    slug: "Tayen-Side-Table",
    shortDescription:
      "I'm a product detail. I'm a great place to add more information about your product such as sizing, material, care and cleaning instructions.",
    prod_sub_cat: 1,
    sku: "ERG891",
    brand: "HIGHQUE WOOD STORE",
    imagePath: "/assets/featured_product_1.png",
    images: {
      main: "/assets/featured_product_1.png",
      others: [
        "/assets/featured_product_1.png",
        "/assets/featured_product_1.png",
        "/assets/featured_product_1.png",
      ],
    },
    isFeatured: true,
    isBestSeller: false,
    isNewArrival: true,
    price: "2000",
    color: "Red",
  },
  {
    id: 2,
    name: "Around Coffee Table",
    slug: "Around-Coffee-Table",
    shortDescription:
      "I'm a product detail. I'm a great place to add more information about your product such as sizing, material, care and cleaning instructions.",
    prod_sub_cat: 2,
    sku: "ERG892",
    brand: "HIGHQUE WOOD STORE",
    imagePath: "/assets/featured_product_2.png",
    images: {
      main: "/assets/featured_product_2.png",
      others: [
        "/assets/featured_product_2.png",
        "/assets/featured_product_2.png",
        "/assets/featured_product_2.png",
      ],
    },
    isFeatured: true,
    isBestSeller: true,
    isNewArrival: false,
    price: "2000",
    color: "Blue",
  },
  {
    id: 3,
    name: "CH24 Wishbone Chair",
    slug: "CH24-Wishbone-Chair",
    shortDescription:
      "I'm a product detail. I'm a great place to add more information about your product such as sizing, material, care and cleaning instructions.",
    prod_sub_cat: 1,
    sku: "ERG893",
    brand: "HIGHQUE WOOD STORE",
    imagePath: "/assets/featured_product_3.png",
    images: {
      main: "/assets/featured_product_3.png",
      others: [
        "/assets/featured_product_3.png",
        "/assets/featured_product_3.png",
        "/assets/featured_product_3.png",
      ],
    },
    isFeatured: true,
    isBestSeller: false,
    isNewArrival: true,
    price: "2000",
    color: "Green",
  },
  {
    id: 4,
    name: "Nikari April Table Low",
    slug: "Nikari-April-Table-Low",
    shortDescription:
      "I'm a product detail. I'm a great place to add more information about your product such as sizing, material, care and cleaning instructions.",
    prod_sub_cat: 3,
    sku: "ERG894",
    brand: "HIGHQUE WOOD STORE",
    imagePath: "/assets/featured_product_4.png",
    images: {
      main: "/assets/featured_product_4.png",
      others: [
        "/assets/featured_product_4.png",
        "/assets/featured_product_4.png",
        "/assets/featured_product_4.png",
      ],
    },
    isFeatured: true,
    isBestSeller: false,
    isNewArrival: true,
    price: "2000",
    color: "Red",
  },
  {
    id: 5,
    name: "Delphine Cane Velvet Chair",
    slug: "Delphine-Cane-Velvet-Chair",
    shortDescription:
      "I'm a product detail. I'm a great place to add more information about your product such as sizing, material, care and cleaning instructions.",
    prod_sub_cat: 1,
    sku: "ERG895",
    brand: "HIGHQUE WOOD STORE",
    imagePath: "/assets/best_seller_product_1.png",
    images: {
      main: "/assets/delphine_cane_velvet_chair.png",
      others: [
        "/assets/delphine_cane_velvet_chair_1.png",
        "/assets/delphine_cane_velvet_chair_2.png",
        "/assets/delphine_cane_velvet_chair_3.png",
      ],
    },
    isFeatured: false,
    isBestSeller: true,
    isNewArrival: false,
    price: "2000",
    color: "Red",
  },
  {
    id: 6,
    name: "Freistil 141 Sofa 2 Seater",
    slug: "Freistil-141-Sofa-2-Seater",
    shortDescription:
      "I'm a product detail. I'm a great place to add more information about your product such as sizing, material, care and cleaning instructions.",
    prod_sub_cat: 2,
    sku: "ERG896",
    brand: "HIGHQUE WOOD STORE",
    imagePath: "/assets/best_seller_product_2.png",
    images: {
      main: "/assets/best_seller_product_2.png",
      others: [
        "/assets/best_seller_product_2.png",
        "/assets/best_seller_product_2.png",
        "/assets/best_seller_product_2.png",
      ],
    },
    isFeatured: false,
    isBestSeller: true,
    isNewArrival: true,
    price: "2000",
    color: "Red",
  },
  {
    id: 7,
    name: "Linea Sohva Sofa",
    slug: "Linea-Sohva-Sofa",
    shortDescription:
      "I'm a product detail. I'm a great place to add more information about your product such as sizing, material, care and cleaning instructions.",
    prod_sub_cat: 3,
    sku: "ERG897",
    brand: "HIGHQUE WOOD STORE",
    imagePath: "/assets/best_seller_product_3.png",
    images: {
      main: "/assets/best_seller_product_3.png",
      others: [
        "/assets/best_seller_product_3.png",
        "/assets/best_seller_product_3.png",
        "/assets/best_seller_product_3.png",
      ],
    },
    isFeatured: false,
    isBestSeller: true,
    isNewArrival: false,
    price: "2000",
    color: "Red",
  },
  {
    id: 8,
    name: "Flask Table Lamp",
    slug: "Flask-Table-Lamp",
    shortDescription:
      "I'm a product detail. I'm a great place to add more information about your product such as sizing, material, care and cleaning instructions.",
    prod_sub_cat: 2,
    sku: "ERG898",
    brand: "HIGHQUE WOOD STORE",
    imagePath: "/assets/flask_table_lamp.png",
    images: {
      main: "/assets/flask_table_lamp.png",
      others: [
        "/assets/flask_table_lamp.png",
        "/assets/flask_table_lamp.png",
        "/assets/flask_table_lamp.png",
      ],
    },
    isFeatured: false,
    isBestSeller: true,
    isNewArrival: false,
    price: "2000",
    color: "Red",
  },
  {
    id: 9,
    name: "Flask Table Lamp",
    slug: "Flask-Table-Lamp",
    shortDescription:
      "I'm a product detail. I'm a great place to add more information about your product such as sizing, material, care and cleaning instructions.",
    prod_sub_cat: 1,
    sku: "ERG899",
    brand: "HIGHQUE WOOD STORE",
    imagePath: "/assets/flask_table_lamp_2.png",
    images: {
      main: "/assets/flask_table_lamp_2.png",
      others: [
        "/assets/flask_table_lamp_2.png",
        "/assets/flask_table_lamp_2.png",
        "/assets/flask_table_lamp_2.png",
      ],
    },
    isFeatured: false,
    isBestSeller: true,
    isNewArrival: false,
    price: "2000",
    color: "Red",
  },
  {
    id: 10,
    name: "Decade Plastic Chair",
    slug: "Decade-Plastic-Chair",
    shortDescription:
      "I'm a product detail. I'm a great place to add more information about your product such as sizing, material, care and cleaning instructions.",
    prod_sub_cat: 1,
    sku: "ERG8100",
    brand: "HIGHQUE WOOD STORE",
    imagePath: "/assets/decade_plastic_chair.png",
    images: {
      main: "/assets/decade_plastic_chair.png",
      others: [
        "/assets/decade_plastic_chair.png",
        "/assets/decade_plastic_chair.png",
        "/assets/decade_plastic_chair.png",
      ],
    },
    isFeatured: false,
    isBestSeller: true,
    isNewArrival: false,
    price: "2000",
    color: "Red",
  },
  {
    id: 11,
    name: "Clara French Press",
    slug: "Clara-French-Press",
    shortDescription:
      "I'm a product detail. I'm a great place to add more information about your product such as sizing, material, care and cleaning instructions.",
    prod_sub_cat: 3,
    sku: "ERG8101",
    brand: "HIGHQUE WOOD STORE",
    imagePath: "/assets/clara_french_press.png",
    images: {
      main: "/assets/clara_french_press.png",
      others: [
        "/assets/clara_french_press.png",
        "/assets/clara_french_press.png",
        "/assets/clara_french_press.png",
      ],
    },
    isFeatured: false,
    isBestSeller: true,
    isNewArrival: false,
    price: "2000",
    color: "Red",
  },
  {
    id: 12,
    name: "Headlands Bell Pendant",
    slug: "Headlands-Bell-Pendant",
    shortDescription:
      "I'm a product detail. I'm a great place to add more information about your product such as sizing, material, care and cleaning instructions.",
    prod_sub_cat: 3,
    sku: "ERG8102",
    brand: "HIGHQUE WOOD STORE",
    imagePath: "/assets/headlands_bell_pendant.png",
    images: {
      main: "/assets/headlands_bell_pendant.png",
      others: [
        "/assets/headlands_bell_pendant.png",
        "/assets/headlands_bell_pendant.png",
        "/assets/headlands_bell_pendant.png",
      ],
    },
    isFeatured: false,
    isBestSeller: true,
    isNewArrival: false,
    price: "2000",
    color: "Red",
  },
];

export const product_types = [
  {
    id: 1,
    name: "Sofa & Couches",
    slug: "sofa-and-couches",
  },
  {
    id: 2,
    name: "Chairs & Recliners",
    slug: "chairs-and-recliners",
  },
  {
    id: 3,
    name: "Console Tables",
    slug: "console-tables",
  },
  {
    id: 4,
    name: "Coffee Tables",
    slug: "coffee-tables",
  },
  {
    id: 5,
    name: "Side Table Stools",
    slug: "side-table-stools",
  },
  {
    id: 6,
    name: "Ottomans",
    slug: "ottomans",
  },
  {
    id: 7,
    name: "Bookcases & Shelves",
    slug: "bookcases-and-shelves",
  },
  {
    id: 8,
    name: "Beds",
    slug: "beds",
  },
  {
    id: 9,
    name: "Bedframes & Headboards",
    slug: "bedrooms-and-headboards",
  },
  {
    id: 10,
    name: "Bedside Tables",
    slug: "bedside-tables",
  },
  {
    id: 11,
    name: "Wardrobes & Armoires",
    slug: "wardrobes-and-armoires",
  },
  {
    id: 12,
    name: "Dressers & Chests",
    slug: "dressers-and-chests",
  },
  {
    id: 13,
    name: "Mattresses",
    slug: "mattresses",
  },
  {
    id: 14,
    name: "Desks",
    slug: "desks",
  },
  {
    id: 15,
    name: "Chairs",
    slug: "chairs",
  },
  {
    id: 16,
    name: "Bookcases",
    slug: "bookcases",
  },
  {
    id: 17,
    name: "Cabinets",
    slug: "cabinets",
  },
  {
    id: 18,
    name: "Accessories",
    slug: "accessories",
  },
  {
    id: 19,
    name: "Dining Set",
    slug: "dining-set",
  },
  {
    id: 20,
    name: "Dining Tables",
    slug: "dining-tables",
  },
  {
    id: 21,
    name: "Dining Chairs",
    slug: "dining-chairs",
  },
  {
    id: 22,
    name: "Dining Console Table",
    slug: "dining-console-table",
  },

  {
    id: 23,
    name: "Bunk Beds",
    slug: "bunk-beds",
  },

  {
    id: 25,
    name: "Mirrors",
    slug: "mirrors",
  },
  {
    id: 26,
    name: "Clocks",
    slug: "clocks",
  },
  {
    id: 27,
    name: "Throw Pillows",
    slug: "throw-pillows",
  },
  {
    id: 28,
    name: "Plants & Vases",
    slug: "plants-and-vases",
  },
  {
    id: 29,
    name: "Blankets",
    slug: "blankets",
  },
  {
    id: 30,
    name: "Duvets",
    slug: "duvets",
  },
  {
    id: 31,
    name: "Pillows",
    slug: "pillows",
  },
  {
    id: 32,
    name: "Pillow Cases",
    slug: "pillow-cases",
  },
  {
    id: 33,
    name: "Downlights",
    slug: "downlights",
  },
  {
    id: 34,
    name: "Pendant Lights",
    slug: "pendant-lights",
  },
  {
    id: 35,
    name: "Spot Lights",
    slug: "spot-lights",
  },
  {
    id: 36,
    name: "Chandeliers",
    slug: "chandeliers",
  },
  {
    id: 37,
    name: "Table",
    slug: "table",
  },
  {
    id: 38,
    name: "Floor",
    slug: "floor",
  },
  {
    id: 39,
    name: "Bedside",
    slug: "bedside",
  },
  {
    id: 40,
    name: "Desk",
    slug: "desk",
  },
  {
    id: 41,
    name: "Cordless",
    slug: "cordless",
  },
];

export const product_sub_categories = [
  {
    id: 1,
    name: "Living Room",
    slug: "living-room",
    thumbnailImagePath: "/assets/living_room.png",
    prod_category: 1,
    types: [
      product_types[0],
      product_types[1],
      product_types[2],
      product_types[3],
      product_types[4],
      product_types[5],
      product_types[6],
    ],
  },
  {
    id: 2,
    name: "Bedroom Furniture",
    slug: "bedroom-furniture",
    thumbnailImagePath: "/assets/bedroom_furniture.png",
    prod_category: 1,
    types: [
      product_types[7],
      product_types[8],
      product_types[9],
      product_types[10],
      product_types[11],
      product_types[12],
    ],
  },
  {
    id: 3,
    name: "Office",
    slug: "office",
    prod_category: 1,
    thumbnailImagePath: "/assets/office.png",
    types: [
      product_types[13],
      product_types[14],
      product_types[15],
      product_types[16],
      product_types[17],
    ],
  },
  {
    id: 4,
    name: "Kitchen & Dining",
    slug: "kitchen-and-dining",
    thumbnailImagePath: "/assets/kitchen_and_dining.png",
    prod_category: 1,
    types: [
      product_types[18],
      product_types[19],
      product_types[20],
      product_types[21],
    ],
  },
  {
    id: 5,
    name: "Kids & Babies",
    slug: "kids-and-babies",
    thumbnailImagePath: "/assets/office.png",
    prod_category: 1,
    types: [
      product_types[22],
      product_types[14],
      product_types[13],
      product_types[11],
    ],
  },
  {
    id: 6,
    name: "Home Decor",
    slug: "home-decor",
    thumbnailImagePath: "/assets/office.png",
    prod_category: 2,
    types: [
      product_types[24],
      product_types[25],
      product_types[26],
      product_types[27],
    ],
  },
  {
    id: 7,
    name: "Beddings",
    slug: "beddings",
    thumbnailImagePath: "/assets/office.png",
    prod_category: 2,
    types: [
      product_types[28],
      product_types[29],
      product_types[30],
      product_types[31],
    ],
  },
  {
    id: 8,
    name: "Ceiling Lights",
    slug: "ceiling-lights",
    thumbnailImagePath: "/assets/office.png",
    prod_category: 3,
    types: [
      product_types[32],
      product_types[33],
      product_types[34],
      product_types[35],
    ],
  },
  {
    id: 8,
    name: "Lamps",
    slug: "lamps",
    thumbnailImagePath: "/assets/office.png",
    prod_category: 3,
    types: [
      product_types[36],
      product_types[37],
      product_types[38],
      product_types[39],
      product_types[40],
    ],
  },
];
