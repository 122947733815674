import React from "react";
import { MdOutlineArrowBackIos } from "react-icons/md";

function CustomBackButton({ onClick }) {
  return (
    <div
      onClick={onClick}
      className=" flex gap-2 text-[16px] font-[600] items-center text-[#318EC9] cursor-pointer"
    >
      <div>
        <MdOutlineArrowBackIos />
      </div>
      <div>Back</div>
    </div>
  );
}

export default CustomBackButton;
