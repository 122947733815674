import React from "react";

function CardTitle({ title, className = "mb-8" }) {
  return (
    <div
      className={`font-[600] text-[20px] max-lg:text-[17px] max-md:text-[14px] ${className}`}
    >
      {title}
    </div>
  );
}

export default CardTitle;
