import axiosInstance from "../utils/axiosInstance";


export const verifyPayment = async (data) => {
    return axiosInstance(`/payments/verify-payment`, "POST", data).then(response => {
        return response;
    })
}

export const getBankList = async (data) => {
    return axiosInstance(`/payments/get-bank-list`, "GET", data).then(response => {
        return response;
    })
}

export const getAccountName = async (data) => {
    return axiosInstance(`/payments/get-account-name/${data.accountNumber}/${data.bankCode}`, "GET", data).then(response => {
        return response;
    })
}