import { useQuery } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom';
import { getAccountName } from '../services/payment-service';

function useAccountName(data) {
    const navigate = useNavigate()
    const { data: accountName, isLoading, error } = useQuery({
        queryKey: [],
        queryFn: async () => {
            const result = await getAccountName(data)
            return result?.data?.data
        },
        onError: (error) => {
            // return error
        }
    })
    return {
        accountName, isLoading, error
    }
}

export default useAccountName