import React from "react";

function FullRoundedButton({
  label,
  onClick,
  className = "text-[16px]",
  disabled = false,
  type = "submit",
  children,
  iconLeft = false,
}) {
  return (
    <button
      type={type}
      onClick={onClick}
      disabled={disabled}
      className={`text-center ${className} font-[600] flex gap-3  border rounded-full py-[9px] px-[30px] items-center justify-center`}
    >
      {!iconLeft && (
        <>
          {label}
          {children}
        </>
      )}
      {iconLeft && (
        <>
          {children}
          {label}
        </>
      )}
    </button>
  );
}

export default FullRoundedButton;
