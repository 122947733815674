import { ADD_ITEM_TO_LIST } from './../listCreationReducer/types';

export function listCreationReducer(state, action) {
    let newState;
    switch (action.type) {
        case ADD_ITEM_TO_LIST:
            let item = state.list.find(l => l == action.payload)
            if (item) {
                newState = { ...state, list: state.list.filter(l => l != item) };
            } else {
                newState = { ...state, list: [...state.list, action.payload] };

            }
            break;
        default:
            newState = { ...state };
            break;
    }
    return newState;
}