import React, { useEffect, useRef, useState } from "react";

import { Formik } from "formik";
import { Form } from "formik";
import HeadingAndDetails from "../HeadingAndDetails";
import CustomInputField from "../../../commons/CustomInputField";
import { validEmail } from "../../../helpers/validator";
import {
  PNG_FILE_TYPE,
  JPG_FILE_TYPE,
  JPEG_FILE_TYPE,
  WEBP_FILE_TYPE,
  DOCX_FILE_TYPE,
  PDF_FILE_TYPE,
} from "../../../constants/texts";
import ConfirmationModal from "../../../commons/modals/ConfirmationModal";
import { getFileTypesFriendly } from "../../../helpers/fileUpload";
import RoundedMdButton from "../../../commons/RoundedMdButton";
import useStates from "../../../hooks/useStates";
import CustomSelectField from "./../../../commons/CustomSelectField";
import useTowns from "./../../../hooks/useTowns";
import { getImageUrl } from "./../../../helpers/extractors";

const fileTypes = [
  PNG_FILE_TYPE,
  JPG_FILE_TYPE,
  JPEG_FILE_TYPE,
  WEBP_FILE_TYPE,
  PDF_FILE_TYPE,
  DOCX_FILE_TYPE,
];

function BusinessDetails({ businessDetails, setBusinessDetails }) {
  const { states: stateList } = useStates();
  const [state, setState] = useState("");
  const [region, setRegion] = useState("");
  const { towns } = useTowns(state);
  const [fileInput, setFileInput] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const fileRef = useRef();

  useEffect(() => {
    if (businessDetails) {
      // if (businessDetails?.cacCertificateDocPath) {
      //   const imageUrl = getImageUrl(businessDetails?.cacCertificateDocPath);
      //   console.log("image url", imageUrl);
      //   const dataTransfer = new DataTransfer();
      //   const fileList = new FileList()
      //   fileList.forEach(file => dataTransfer.items.add(new File(imageUrl)))
      //   // if (fileRef.current) {
      //   //   fileRef.current.files =
      //   //     getImageUrl(imageUrl) ?? "";
      //   // }
      // }
    }
  }, [businessDetails]);

  return (
    <div className="mt-6">
      <HeadingAndDetails
        title={"Business Details"}
        description={"Kindly complete the form below"}
      />
      <div className="mt-2"></div>
      <Formik
        enableReinitialize
        initialValues={{
          businessEmail: businessDetails?.businessEmail ?? "",
          businessName: businessDetails?.businessName ?? "",
          registeredAddress: businessDetails?.registeredAddress ?? "",
          state: businessDetails?.state ?? "",
          region: businessDetails?.region ?? "",
          yearsOfOperation: businessDetails?.yearsOfOperation ?? "",
          taxId: businessDetails?.taxId ?? "",
          cacCertificate: businessDetails?.cacCertificateDocPath ?? "",
        }}
        validate={(values) => {
          const errors = {};

          if (values.cacCertificate === null) {
            setFileInput("");
            setOpenModal(true);
          }

          if (!values.businessName) {
            errors.businessName = "Business name is required";
          }
          if (!values.businessEmail) {
            errors.businessEmail = "Business email is required";
          } else if (!validEmail(values.businessEmail)) {
            errors.businessEmail = "Invalid email address";
          }

          if (!values.registeredAddress) {
            errors.registeredAddress = "Registered address is required";
          }

          if (!values.yearsOfOperation) {
            errors.yearsOfOperation = "Years of operation is required";
          }

          if (!values.state || values.state == "") {
            setState("");
            errors.state = "State is required";
          } else {
            setState(values.state);
          }
          if (!values.region) {
            errors.region = "Region is required";
          } else {
            setRegion(values.region);
          }

          if (!values.taxId) {
            errors.taxId = "Tax Id is required";
          }

          if (!values.cacCertificate) {
            errors.cacCertificate = "CAC certificate is required";
          }

          if (Object.keys(errors).length == 0) {
            setBusinessDetails(values);
          }

          console.log("errors", errors);
          return errors;
        }}
      >
        {({ isSubmitting, setFieldValue, setFieldError, values }) => (
          <Form>
            <div className="flex max-md:flex-col max-md:gap-0 items-center gap-4">
              <div className="w-full">
                <CustomInputField
                  name={"businessName"}
                  required={true}
                  placeholder="Enter business name"
                  label={"Business Name"}
                />
              </div>
              <div className="w-full">
                <CustomInputField
                  name={"businessEmail"}
                  required={true}
                  placeholder="Enter business email"
                  label={"Business Email"}
                />
              </div>
              <div className="w-full">
                <CustomInputField
                  name={"registeredAddress"}
                  required={true}
                  placeholder="Enter registered address"
                  label={"Registered Address"}
                />
              </div>
            </div>
            <div className="flex max-md:flex-col max-md:gap-0 items-center gap-4">
              <div className="w-full">
                <CustomSelectField
                  name={"state"}
                  required={true}
                  placeholder="Select state"
                  type="text"
                  label={"State"}
                  valueKey={"state_code"}
                  keyValue={"name"}
                  selectedValue={values.state}
                  list={stateList?.data ?? []}
                  onChange={(value) => setFieldValue("state", value)}
                  setFieldError={setFieldError}
                />
              </div>
              <div className="w-full">
                {!state && (
                  <CustomInputField
                    readOnly={true}
                    placeholder="Select region"
                    name="region"
                    type="text"
                    label={"Region"}
                  />
                )}
                {state && (
                  <CustomSelectField
                    name={"region"}
                    required={true}
                    placeholder="Select region"
                    type="text"
                    label={"Region"}
                    valueKey={"name"}
                    keyValue={"name"}
                    selectedValue={values.region}
                    list={!towns?.data?.message ? towns?.data : []}
                    onChange={(value) => setFieldValue("region", value)}
                    setFieldError={setFieldError}
                    disabled={state == ""}
                  />
                )}
              </div>
              <div className="w-full">
                <CustomInputField
                  name={"cacCertificate"}
                  required={true}
                  type="file"
                  placeholder=""
                  label={"CAC Certificate"}
                  fileTypes={fileTypes}
                  setFieldValue={setFieldValue}
                  setFieldError={setFieldError}
                  inputRef={fileRef}
                  fileCallback={setFileInput}
                  selectedFile={values.cacCertificate}
                />
              </div>
            </div>
            <div className="flex max-md:flex-col max-md:gap-0 items-center gap-4">
              <div className="w-full">
                <CustomInputField
                  name={"yearsOfOperation"}
                  required={true}
                  placeholder="Enter years of operation"
                  type="number"
                  label={"Years of Operation"}
                />
              </div>
              <div className="w-full">
                <CustomInputField
                  name={"taxId"}
                  required={true}
                  placeholder="Enter tax identification number"
                  label={"Tax Identification Number"}
                />
              </div>
              <div className="w-full"></div>
            </div>
          </Form>
        )}
      </Formik>

      <ConfirmationModal
        isOpen={openModal && fileInput != ""}
        type={"warning"}
        title={"File upload error"}
        description={""}
        subText={
          "Invalid file type. Acceptable types (" +
          getFileTypesFriendly(fileTypes) +
          ")"
        }
        onClose={() => {
          setOpenModal(false);
        }}
      >
        <div className="my-5">
          <RoundedMdButton
            label={"OK"}
            onClick={() => {
              setOpenModal(false);
            }}
          />
        </div>
      </ConfirmationModal>
    </div>
  );
}

export default BusinessDetails;
