import { useMutation, useQueryClient } from '@tanstack/react-query'
import { USER_QUERY_KEY, VENDORS_QUERY_KEY } from '../constants/queryKeys';
import { vetUnregisteredVendor } from '../services/vendors-services';

function useVetUnregisteredVendor() {
    const queryClient = useQueryClient();
    const { mutateAsync: vetUnregistered, isLoading, error, data } = useMutation({
        mutationFn: (vettingData) => vetUnregisteredVendor(vettingData),
        onSuccess: (data) => {
            queryClient.setQueryData([USER_QUERY_KEY], data?.data?.data)
        },
        onError: (error) => {
            // return error
        }
    })
    return {
        vetUnregistered, isLoading, error, data
    }
}

export default useVetUnregisteredVendor