import React from 'react'
import BottomHeader from './BottomHeader'
import TopHeader from './TopHeader'

function Header() {
  return (
    <div>
        <TopHeader />
        <BottomHeader />
    </div>
  )
}

export default Header