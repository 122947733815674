import React, { useState, useEffect } from "react";
import Layout from "../../commons/Layout";
import PageBreadCrumb from "../../commons/PageBreadCrumb";
import { FaRegUser } from "react-icons/fa6";
import { BsBox2 } from "react-icons/bs";
import { MdOutlineRateReview } from "react-icons/md";
import { IoIosHeartEmpty } from "react-icons/io";
import { TfiLock } from "react-icons/tfi";
import { RiDeleteBin7Line } from "react-icons/ri";
import AccountLinkItem from "../../commons/AccountLinkItem";

import AccountOrders from "./AccountOrders";
import AccountProfile from "./AccountProfile";
import AccountSavedItems from "./AccountSavedItems";
import AccountAddressBook from "./AccountAddressBook";
import AccountNewAddress from "./AccountNewAddress";
import AccountChangePassword from "./AccountChangePassword";
import LocationIcon from "../../commons/LocationIcon";
import AccountPendingReviews from "./AccountPendingReviews";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  ADDRESS_BOOK,
  CHANGE_PASSWORD,
  DELETE_ACCOUNT,
  EDIT_ADDRESS,
  MY_BUSINESS_PROFILE,
  MY_ORDERS,
  MY_PROFILE,
  NEW_ADDRESS,
  PENDING_REVIEWS,
  SAVED_ITEMS,
} from "../../constants/data";
import AccountDelete from "./AccountDelete";
import useUserPendingReviews from "../../hooks/useUserPendingReviews";
import useUser from "../../hooks/useUser";
import BusinessVetting from "../vendor_onboarding/seller/BusinessVetting";

function Account() {
  const [itemIndex, setItemIndex] = useState(1);
  const [updated, setUpdated] = useState(0);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedTab, setSelectedTab] = useState(
    searchParams.get("tab") ?? MY_PROFILE
  );

  const { user } = useUser();

  const setActiveTab = (tabName) => {
    setUpdated((prev) => ++prev);
    setSearchParams((params) => {
      params.set("tab", tabName);
      return params;
    });
    setSelectedTab(tabName);
  };

  useEffect(() => {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  }, [searchParams, selectedTab, updated]);

  useEffect(() => {
    if (selectedTab == MY_BUSINESS_PROFILE) {
      navigate("/edit-business-profile");
    }
  }, [selectedTab]);

  return (
    <Layout bgClassName="bg-gray-100">
      <div className="w-[85%] max-md:w-[95%] mx-auto pt-6 pb-20">
        <PageBreadCrumb
          isActiveIndex={1}
          mainText="Home"
          subText={"My Account"}
        />
        <div className="mt-8"></div>

        <div className="flex max-lg:flex-col gap-10">
          {/* Left section */}
          <div className="shadow-md rounded-lg w-[25%] max-lg:w-full flex flex-col max-lg:flex-row overflow-auto gap-4 p-6 max-md:p-4 bg-white">
            <AccountLinkItem
              label="My Profile"
              index={MY_PROFILE}
              onClick={() => {
                setActiveTab(MY_PROFILE);
              }}
              selectedIndex={selectedTab}
            >
              <FaRegUser />
            </AccountLinkItem>
            <AccountLinkItem
              label="My Business Profile"
              index={MY_BUSINESS_PROFILE}
              onClick={() => {
                navigate("/edit-business-profile");
              }}
              selectedIndex={selectedTab}
            >
              <FaRegUser />
            </AccountLinkItem>
            <hr className="mb-3" />
            <AccountLinkItem
              label="Delete Account"
              className="text-[#F56630]"
              index={DELETE_ACCOUNT}
              onClick={() => {
                setActiveTab(DELETE_ACCOUNT);
                // setShowDeleteModal(true);
              }}
              selectedIndex={selectedTab}
            >
              <RiDeleteBin7Line />
            </AccountLinkItem>
          </div>

          {/* Right Section */}
          {selectedTab === MY_PROFILE && <AccountProfile />}
          {selectedTab === DELETE_ACCOUNT && (
            <AccountDelete onBackClick={() => setActiveTab(MY_PROFILE)} />
          )}
        </div>
      </div>

      {/* <ConfirmationModal
        type="warning"
        isOpen={showDeleteModal}
        widthClass={"w-[40%]"}
        onClose={() => {
          setShowDeleteModal(false);
          navigate(-1)
          navigate(0)
        }}
        showCloseButton={true}
        description="Deleting your account will remove all your information from our
        database. This cannot be undone."
        subText='To confirm this, type "DELETE"'
        title="Delete Account"
      >
        <Formik
          initialValues={{
            content: "",
          }}
          onSubmit={(values, { setSubmitting, resetForm }) => {}}
          validate={(values) => {
            const errors = {};
            if (!values.content) {
              errors.content = "Required";
            }

            return errors;
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <CustomTextField
                type="text"
                name="content"
                label=""
                placeholder=""
                rows={2}
              />
              <div className="text-right flex justify-end gap-3">
                <FullRoundedButton
                  label="Cancel"
                  type="button"
                  className="bg-[#D0D5DD]"
                  onClick={() => setShowDeleteModal(false)}
                />
                <FullRoundedButton
                  label="Delete"
                  type="submit"
                  className="bg-[#FF0000] text-white"
                  disabled={isSubmitting}
                />
              </div>
            </Form>
          )}
        </Formik>
      </ConfirmationModal> */}
    </Layout>
  );
}

export default Account;
