import React, { useState } from "react";
import Layout from "../../commons/Layout";
import CardTitleBig from "../../commons/PageTitleBig";
import CustomInputField from "../../commons/CustomInputField";
import RoundedMdButton from "../../commons/RoundedMdButton";
import { Formik, Form } from "formik";
import useLogin from "../../hooks/useLogin";
import CustomThreeDotsSpinner from "../../commons/spinners/CustomThreeDotsSpinner";
import CustomSnackBar from "../../commons/CustomSnackBar";
import { Link, useNavigate } from "react-router-dom";
import { encryptData } from "../../helpers/encryptor";

function Login() {
  const navigate = useNavigate();
  const { logVendorIn } = useLogin();
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [isError, setIsError] = useState(false);

  return (
    <Layout bgClassName="bg-gray-100">
      <div className="flex items-center py-10">
        <div className="shadow-md rounded-lg  bg-white p-6 w-[32%] max-lg:w-3/5 max-md:w-4/5 mx-auto">
          <CardTitleBig title="Sign in to your account" />{" "}
          <Formik
            initialValues={{
              email: "",
              password: "",
            }}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              logVendorIn({
                email: encryptData(values.email),
                password: encryptData(values.password),
              })
                .then((res) => {
                  navigate("/dashboard/products");
                  setIsError(false);
                  resetForm();
                })
                .catch((err) => {
                  setSnackBarMessage(err?.message);
                  setIsError(true);
                  setOpenSnackBar(true);
                })
                .finally(() => {
                  setSubmitting(false);
                });
            }}
            validate={(values) => {
              const errors = {};
              if (!values.email) {
                errors.email = "Email is required";
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = "Invalid email address";
              }
              if (!values.password) {
                errors.password = "Password is required";
              }
              return errors;
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <CustomInputField
                  label={"Email Address"}
                  type="email"
                  name="email"
                  placeholder="Please enter your email address"
                  disabled={isSubmitting}
                />
                <CustomInputField
                  label={"Password"}
                  type="password"
                  name="password"
                  placeholder="Please enter your password"
                  disabled={isSubmitting}
                />
                {!isSubmitting && (
                  <p className="font-[500] text-[14px] max-md:text-[12px] text-[#FF9D21] my-5 cursor-pointer">
                    <Link to="/auth/forgot-password">Forgot Password?</Link>
                  </p>
                )}
                <RoundedMdButton
                  label={isSubmitting ? "Please wait" : "Sign in"}
                  className="bg-[#FF9D21] text-white hover:bg-orange-600"
                  disabled={isSubmitting}
                  type="submit"
                  left={false}
                >
                  {isSubmitting && (
                    <CustomThreeDotsSpinner
                      height="20"
                      width="20"
                      color="white"
                    />
                  )}
                </RoundedMdButton>

                {!isSubmitting && (
                  <div className="flex gap-2 items-center justify-center">
                    <p className="text-gray-400">Are you new here? Please</p>
                    <p className="font-[500] text-[#FF9D21] my-5 cursor-pointer">
                      <Link to="/onboarding">Create account</Link>
                    </p>
                  </div>
                )}
              </Form>
            )}
          </Formik>
        </div>
      </div>

      <CustomSnackBar
        isOpen={openSnackBar}
        onClose={() => setOpenSnackBar(false)}
        message={snackBarMessage}
        isError={isError}
      />
    </Layout>
  );
}

export default Login;
