import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom';
import { SUBSCRIPTION_ITEM_QUERY_KEY } from '../constants/queryKeys';
import { addToSubsription } from "../services/subscription-services";

function useAddToSubscription() {
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const { mutateAsync: addToSubscriptionList, isLoading, error, data } = useMutation({
        mutationFn: (createData) => addToSubsription(createData),
        onSuccess: (data) => {
            queryClient.setQueryData([SUBSCRIPTION_ITEM_QUERY_KEY], data?.data?.data)
            // navigate("/my-account")
        },
        onError: (error) => {
            // return error
        }
    })
    return {
        addToSubscriptionList, isLoading, error, data
    }
}

export default useAddToSubscription