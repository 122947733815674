import { getNetworkErrorMessage } from "../helpers/network";
import axiosInstance from "../utils/axiosInstance";


export const fetchProductCategories = async () => {
    return axiosInstance(`/categories`, "GET", null, null).then(response => {
        return response;
    }).catch(err => {
        // return err
        getNetworkErrorMessage(err)
    })
}

export const fetchProductCategoryBySlug = async (slug) => {
    return axiosInstance(`/categories/slug/${slug}`, "GET", null, null).then(response => {
        return response;
    }).catch(err => {
        // return err
        getNetworkErrorMessage(err)
    })
}

