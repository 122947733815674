import { useMutation, useQueryClient } from '@tanstack/react-query'
import { USER_QUERY_KEY } from '../constants/queryKeys';
import { registerVendor } from "../services/auth-service";

function useSignupVendor() {
    const queryClient = useQueryClient();
    const { mutateAsync: signVendorUp, isLoading, error, data } = useMutation({
        mutationFn: (registerData) => registerVendor(registerData),
        onSuccess: (data) => {
            // queryClient.setQueryData([USER_QUERY_KEY], data?.data?.data)
        },
        onError: (error) => {
            // return error
        }
    })
    return {
        signVendorUp, isLoading, error, data
    }
}

export default useSignupVendor