import { useQuery } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom';
import { FILTERED_PRODUCTS_QUERY_KEY } from '../constants/queryKeys';
import { filterProducts } from '../services/products-services';

function useFilterProducts(pageSize, pageNumber, categoryList, subcategoryList, typeList, brandList, colorList, priceList, sorter) {
    const navigate = useNavigate()
    const { data: products, isLoading, error } = useQuery({
        queryKey: [FILTERED_PRODUCTS_QUERY_KEY, pageSize, pageNumber, categoryList, subcategoryList, typeList, brandList, colorList, priceList, sorter],
        queryFn: async () => {

            const result = await filterProducts(pageSize, pageNumber, categoryList, subcategoryList, typeList, brandList, colorList, priceList, sorter)
            return result?.data?.data

        },
        onError: (error) => {
            // return error
        }
    })
    return {
        products: products ?? [], isLoading, error
    }
}

export default useFilterProducts