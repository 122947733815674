import { useQuery } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom';
import { COUNTRIES_QUERY_KEY } from '../constants/queryKeys';
import { getCountryList } from '../services/states-service';

function useCountries() {
    const navigate = useNavigate()
    const { data: countries, isLoading, error } = useQuery({
        queryKey: [COUNTRIES_QUERY_KEY],
        queryFn: async () => {
            const result = await getCountryList()
            return result?.data?.data
        },
        onError: (error) => {
            // return error
        }
    })
    return {
        countries, isLoading, error
    }
}

export default useCountries