import React from "react";

function InfoCard({ text, value, icon }) {
  return (
    <div className="flex items-center gap-2 justify-between p-4 max-md:p-2 rounded-md border-[1.5px] w-full max-sm:min-w-[170px] border">
      <div className="flex flex-col justify-between gap-3 items-start">
        <p className="font-[500] text-[16px] max-lg:text-[14px] max-md:text-[12px]">
          {text}
        </p>
        <p className="font-[600] text-[24px] max-lg:text-[19px] max-md:text-[14px]">
          {value}
        </p>
      </div>
      <div className="w-[30px] h-[30px] max-md:w-[20px] max-md:h-[20px]">
        <img src={icon} className="w-full h-full object-cover" />
      </div>
    </div>
  );
}

export default InfoCard;
