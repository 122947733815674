import React, { useEffect, useState } from "react";
import Layout from "../../../commons/Layout";
import PageBreadCrumb from "../../../commons/PageBreadCrumb";
import InfoCard from "./../InfoCard";
import NavTab from "./../NavTab";
import ProductRowItem from "./ProductRowItem";
import { APPROVED, REJECTED } from "../../../constants/texts";
import { PENDING } from "./../../../constants/texts";
import AllNavBars from "../AllNavBars";
import useUser from "../../../hooks/useUser";
import useFilterProducts from "../../../hooks/useFilterProducts";
import Pagination from "../../../commons/Pagination";
import CustomNoRecordFound from "../../../commons/CustomNoRecordFound";
import NotFoundSVG from "../../../commons/NotFoundSVG";
import useCustomInfoDisplay from "../../../hooks/useCustomInfoDisplay";
import { useLocation } from "react-router-dom";
import CustomSnackBar from "../../../commons/CustomSnackBar";

function Products() {
  const [selected, setSelected] = useState("");
  const location = useLocation();
  const { user } = useUser();
  const [pageSize, setPageSize] = useState(4);
  const [pageNumber, setPageNumber] = useState(1);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [isError, setIsError] = useState(false);

  const { products, isLoading } = useFilterProducts(
    pageSize,
    pageNumber,
    [],
    [],
    [],
    [user?.id],
    [],
    [],
    selected
  );

  useEffect(() => {
    setPageNumber(1);
  }, [selected]);

  const { messageState, setInfoDetails, InfoDisplayComponent } =
    useCustomInfoDisplay(location?.state?.message, location?.state?.isError);

  const handleClick = (details) => {
    setInfoDetails(details);
  };

  return (
    <Layout>
      <div className="w-[78%] max-lg:w-[85%] max-md:w-[95%] mx-auto mt-5">
        <PageBreadCrumb
          isActiveIndex={1}
          mainText="Home"
          subText={"Vendor Dashboard"}
        />

        <AllNavBars
          user={user}
          pageDescription={"Add and manage your products"}
          selectedIndex={1}
        />

        <div className="flex justify-between items-center gap-3 my-4 w-full overflow-x-auto">
          <InfoCard
            text="Approved"
            value={products?.approvedProducts?.length}
            icon={"/assets/approved_icon.png"}
          />
          <InfoCard
            text="Pending"
            value={products?.pendingProducts?.length}
            icon={"/assets/pending_icon.png"}
          />
          <InfoCard
            text="Rejected"
            value={products?.rejectedProducts?.length}
            icon={"/assets/rejected_icon.png"}
          />
        </div>

        <div className="flex items-center gap-3 mt-6 w-full overflow-x-auto">
          <NavTab
            label={"All Products"}
            value={products?.totalRecord}
            isSelected={selected}
            actualValue={""}
            onClick={() => setSelected("")}
          />
          <NavTab
            label={"Approved"}
            value={products?.approvedProducts?.length}
            isSelected={selected}
            actualValue={APPROVED}
            onClick={() => setSelected(APPROVED)}
          />
          <NavTab
            label={"Pending"}
            value={products?.pendingProducts?.length}
            isSelected={selected}
            actualValue={PENDING}
            onClick={() => setSelected(PENDING)}
          />
          <NavTab
            label={"Rejected"}
            value={products?.rejectedProducts?.length}
            isSelected={selected}
            actualValue={REJECTED}
            onClick={() => setSelected(REJECTED)}
          />
        </div>
        <div className="w-full h-[1.2px] bg-gray-100 -mt-[1px]"></div>

        <div class="relative overflow-x-scroll my-8">
          <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead class="text-xs text-gray-700 bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr className="text-left">
                <th scope="col" class="px-2 py-3">
                  Product
                </th>
                <th scope="col" class="px-2 py-3">
                  Category
                </th>
                <th scope="col" class="px-2 py-3">
                  Subcategory
                </th>
                <th scope="col" class="px-2 py-3">
                  Price
                </th>
                <th scope="col" class="px-2 py-3">
                  Quantity
                </th>
                <th scope="col" class="px-2 py-3">
                  Status
                </th>
                <th scope="col" class="px-2 py-3">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {products?.records?.map((product) => {
                return (
                  <ProductRowItem
                    user={user}
                    product={product}
                    isError={isError}
                    setIsError={setIsError}
                    setOpenSnackBar={setOpenSnackBar}
                    openSnackBar={openSnackBar}
                    snackBarMessage={snackBarMessage}
                    setSnackBarMessage={setSnackBarMessage}
                  />
                );
              })}
            </tbody>
          </table>

          {!isLoading && products && products?.records?.length === 0 && (
            <div className="flex items-center justify-center h-[70vh] max-md:h-[30vh]">
              <CustomNoRecordFound
                showButton={false}
                buttonText={"Got it"}
                label={`No ${selected} products found`}
              >
                <NotFoundSVG />
              </CustomNoRecordFound>
            </div>
          )}

          {!isLoading && products && products?.records?.length > 0 && (
            <div className="mt-4 mb-5">
              <Pagination
                onPageChange={setPageNumber}
                currentPage={pageNumber}
                totalCount={products?.totalRecord ?? 0}
                pageSize={pageSize}
              />
            </div>
          )}
        </div>
      </div>

      {InfoDisplayComponent}

      <CustomSnackBar
        isOpen={openSnackBar}
        onClose={() => setOpenSnackBar(false)}
        message={snackBarMessage}
        isError={isError}
      />
    </Layout>
  );
}

export default Products;
