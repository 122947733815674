export const saveIntendedRoute = (intendedRoute) => {
    localStorage.setItem("intended_route", intendedRoute);
}

export const getIntendedRoute = (defaultRoute = "/") => {
    const intendedRoute = localStorage.getItem("intended_route")
    return intendedRoute ? intendedRoute : defaultRoute
}

export const removeIntendedRoute = () => {
    localStorage.removeItem("intended_route")
}