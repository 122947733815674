import React from "react";

function NavTab({
  label,
  onClick,
  value,
  actualValue,
  isSelected,
  selectedColorClass = "orange-500",
}) {
  const selected = isSelected == actualValue;
  return (
    <div
      onClick={onClick}
      className={`flex border-b-2 items-center gap-2 cursor-pointer p-2 hover:border-orange-500 ${
        selected ? "border-orange-500" : "border-gray-100"
      }`}
    >
      <div
        className={`${
          selected ? "text-orange-500" : ""
        } font-[500] text-[16px] max-lg:text-[14px] max-md:text-[12px]`}
      >
        {label}
      </div>
      <span
        className={`${
          selected ? "bg-orange-500 text-white" : "bg-gray-200"
        } px-[0.6em] py-[0.05em] rounded-md`}
      >
        {value}
      </span>
    </div>
  );
}

export default NavTab;
