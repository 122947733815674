import { useState } from "react";
import Layout from "../../commons/Layout";
import CardTitleBig from "../../commons/PageTitleBig";
import CustomInputField from "../../commons/CustomInputField";
import RoundedMdButton from "../../commons/RoundedMdButton";
import { Form, Formik } from "formik";
import { Link, useParams } from "react-router-dom";
import useResetPassword from "../../hooks/useResetPassword";
import CustomSnackBar from "../../commons/CustomSnackBar";
import CustomThreeDotsSpinner from "../../commons/spinners/CustomThreeDotsSpinner";

function ResetPasswordToken() {
  const { token } = useParams();
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [isError, setIsError] = useState(false);

  const openSnackbar = (isError, message) => {
    setSnackBarMessage(message);
    setOpenSnackBar(true);
    setIsError(isError ? true : false);
  };

  const { resetUserPassword } = useResetPassword(openSnackbar);

  return (
    <Layout bgClassName="bg-gray-100">
      <div className="flex items-center py-20">
        <div className="shadow-md rounded-lg  bg-white p-6 w-[32%] max-lg:w-3/5 max-md:w-4/5 mx-auto">
          <CardTitleBig title="Reset your password" />
          <p className="text-[#667185] text-[14px] max-md:text-[12px] font-[500] mb-5">
            Please enter your new password.
          </p>

          <Formik
            initialValues={{
              email: "",
            }}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              resetUserPassword({
                password: values.password,
                token,
              })
                .then((res) => {
                  openSnackbar(false, "Password reset successful");
                  resetForm();
                })
                .catch((err) => {
                  openSnackbar(true, err?.message);
                })
                .finally(() => {
                  setSubmitting(false);
                });
            }}
            validate={(values) => {
              const errors = {};
              if (!values.password) {
                errors.password = "Password is required";
              }
              return errors;
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <CustomInputField
                  label={"Password"}
                  type="password"
                  name="password"
                  placeholder="Please enter your new password"
                  disabled={isSubmitting}
                />
                <RoundedMdButton
                  label={isSubmitting ? "Please wait" : "Reset password"}
                  type="submit"
                  disabled={isSubmitting}
                  className="bg-[#FF9D21] text-white hover:bg-orange-600"
                >
                  {isSubmitting && (
                    <CustomThreeDotsSpinner
                      height="20"
                      width="20"
                      color="white"
                    />
                  )}
                </RoundedMdButton>
                <Link to="/auth/login">
                  <RoundedMdButton
                    label="Cancel"
                    disabled={isSubmitting}
                    className="bg-white text-black border hover:text-[#FF9D21]"
                  ></RoundedMdButton>
                </Link>
              </Form>
            )}
          </Formik>
        </div>
      </div>

      <CustomSnackBar
        isOpen={openSnackBar}
        onClose={() => setOpenSnackBar(false)}
        message={snackBarMessage}
        isError={isError}
      />
    </Layout>
  );
}

export default ResetPasswordToken;
