import { useQuery } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom';
import { PRODUCT_CATEGORIES_QUERY_KEY } from '../constants/queryKeys';
import { fetchProductCategories } from '../services/product_categories-services';

function useProductCategories() {
    const navigate = useNavigate()
    const { data: productCategories, isLoading, error } = useQuery({
        queryKey: [PRODUCT_CATEGORIES_QUERY_KEY],
        queryFn: async () => {
            const result = await fetchProductCategories()
            return result?.data?.data
        },
        onError: (error) => {
            // return error
        }
    })
    return {
        productCategories, isLoading, error
    }
}

export default useProductCategories