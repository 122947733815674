export const econtractStatements = [
    {
        title: "Introduction:",
        content: "Wudify Limited operates an e-commerce platform in Nigeria for buying and selling furniture, lighting, and decor products. This platform provides logistics, installation, and payment services."
    },
    {
        title: "Vendor Registration and Responsibilities:",
        content: "When you register as a Vendor, you agree to Wudify's terms, policies, and guidelines. Employees and their family members must get approval through Wudify’s Conflict of Interest Procedures before registering. Your account undergoes review by Wudify before it becomes active. As a vendor, you are responsible for managing your store, listings, and products; this includes obtaining any required licenses for selling your products and registering with relevant tax authorities to pay all applicable taxes for your sales. Opening more than one Vendor account requires valid reasons approved by Wudify. You must conduct your business on the marketplace with skill, care, and good faith towards Wudify and buyers, while adhering to our Partner Code of Conduct, policies, guidelines, ethical practices, industry standards, and all applicable laws. Wudify reserves the right to reject or remove your store if you violate these terms."
    },
    {
        title: "Product Listings and Rules:",
        content: "List your products accurately with detailed information, specifications, and images. Each product should have only one listing. Ensure your listings comply with marketplace content rules and meet all applicable legal and regulatory requirements. Wudify reviews and approves listings before they are published. We reserve the right to reject, unpublished, and/or delete any listings that violate these terms and conditions or any of Wudify's codes, policies, or guidelines. Keep your listings updated and remove any products that are no longer available."
    },
    {
        title: "Product Categories and Restrictions:",
        content: "You can only list products within the specified categories on the marketplace. Prohibited items include but not limited to drugs, weapons, and counterfeit products. Wudify maintains a zero-tolerance policy for counterfeit items, with penalties that may include permanent prohibition from the platform. You must provide proof of product quality when requested."
    },
    {
        title: "Pricing and Sales Terms:",
        content: "Set your product prices inclusive of VAT and any other applicable taxes in Nigeria's currency. You have control over your prices but must adhere to competition laws."
    },
    {
        title: "Sales, Delivery, and Risk:",
        content: "Once Wudify confirms a sale, Take clear images of all sides and details around the product, Pack and label the products according to the Packaging Guidelines, Prepare the products for pickup as per the Delivery Guidelines. Wudify reserves the right to inspect products and return any that do not meet packaging or quality control requirements. Wudify bears the risk of loss or damage to products until delivery to the buyer. Penalties may apply if you fail to comply with packaging or delivery guidelines."
    },
    {
        title: "Commissions, Fees, and Payments:",
        content: "Wudify charges commissions and fees as outlined in the Service Level Agreement (SLA), including transaction fees, advertising fees, and listing fees, which apply regardless of whether a sale is completed or not. These commissions and fees may be subject to change with prior notice. Wudify collects payments, deducts fees, and remits the balance using specified payment methods. Evidence of payment to your nominated account serves as conclusive proof of receipt. It is important to maintain accurate account details for smooth transactions. Order invoices or payouts and dispute resolutions are managed by Wudify. Any amounts owed due to product returns after payout must be settled promptly. Payments to vendors may be delayed or suspended during fraud or breach investigations. Disputes regarding transactions or account statements must be reported within 7 working days."
    },
    {
        title: "Tax Responsibilities:",
        content: "All product prices on the marketplace include VAT and any other applicable taxes. You are responsible for tax compliance related to your transactions. Vendor agrees to indemnify Wudify for any tax liabilities determined by authorities and must promptly provide requested tax-related information and documentation."
    },
    {
        title: "Customer Service Standards:",
        content: "You must respond to customer inquiries within 24 hours and resolve issues promptly and professionally. Adhere to Wudify's guidelines for handling complaints and disputes, ensuring fair resolutions and maintaining high customer satisfaction levels."
    },
    {
        title: "Product Returns and Refunds:",
        content: "Returns are accepted for damaged, defective, or incorrect items based on specified conditions. Refunds must be processed within 48 hours upon return approval. Restocking fees may apply under certain conditions. Exchange or replacement options will be offered based on availability. Disputes regarding returns or refunds will be resolved following Wudify's established procedures, including mediation or escalation if necessary."
    },
    {
        title: "Performance Metrics and Termination:",
        content: "You must maintain standards such as order fulfillment rates and customer satisfaction scores. Wudify reserves the right to suspend or terminate a seller's account for consistent failure to meet these metrics or breach of terms. Vendors will receive notice and an opportunity to rectify issues within a specified time frame."
    },
    {
        title: "Force Majeure:",
        content: "In the event of force majeure (e.g., natural disasters, strikes), either party affected must promptly notify the other, detailing the impact. Wudify may temporarily suspend its obligations, and both parties will work to resume normal operations as soon as feasible. If the force majeure event extends beyond 30 days, either party may terminate the agreement without liability except for accrued obligations."
    },
    {
        title: "Breach, Confidentiality, and Indemnity:",
        content: "Breaching these terms may result in formal warnings, suspensions, or legal actions. Penalties vary and are subject to change. Unsatisfactory operational performance or negative ratings will also incur consequences. Additionally, you must maintain appropriate insurance coverage for your products under this agreement. Keep business-related information confidential; unauthorized disclosure may lead to penalties. Advertising to buyers requires Wudify's consent, and you are responsible for handling personal data properly. You indemnify Wudify against expenses incurred due to misuse of personal data."
    },
    {
        title: "Legal Compliance and Disputes:",
        content: "These terms are subject to Nigerian laws and Wudify's general terms and conditions. Disputes are governed by the laws and jurisdiction of the respective territory. These terms constitute the entire agreement between you and Wudify, superseding all previous agreements. Any revisions require express agreement."
    },

]