import { useMutation } from "@tanstack/react-query";
import { forgetPassword } from "../services/auth-service";

function useForgetPassword(openSnackbar) {
  const {
    mutateAsync: forgetUserPassword,
    isLoading,
    error,
    data,
  } = useMutation({
    mutationFn: (email) => forgetPassword(email),
    onSuccess: () => {
      openSnackbar(false, "An email has been sent to you");
    },
    onError: (error) => {
      openSnackbar(true, error?.message);
    },
  });
  return {
    forgetUserPassword,
    isLoading,
    error,
    data,
  };
}

export default useForgetPassword;
