import React, { useState } from "react";
import { ErrorMessage } from "formik";
import { capitalizeFirstLetter } from "../helpers/strings";

function CustomSelectField({
  label,
  type = "text",
  placeholder = "",
  valueKey = "",
  keyValue = "",
  name,
  disabled = false,
  list = [],
  onChange,
  required = true,
  selectedValue = null,
}) {
  const [fieldError, setFieldError] = useState("");
  const [value, setValue] = useState(selectedValue)

  const handleOnChange = (value) => {
    setFieldError("");
    setValue(null)
    onChange(value);
    if (!value && required) {
      setFieldError(`${capitalizeFirstLetter(label)} is required`);
    }
  };

  return (
    <div className="mb-5 text-left">
      <div className="mb-2 text-[14px] font-[500]">
        {label}
        {!required && <span className="text-[#FF9D21] ml-2">(Optional)</span>}
        {required && <span className="text-red-300 ml-1">*</span>}
      </div>
      <div>
        <select
          className="w-full text-[14px] font-[400] py-3 pl-3 border rounded-md outline-none"
          type={type}
          placeholder={placeholder}
          onChange={(e) => handleOnChange(e.target.value)}
          onBlur={(e) => handleOnChange(e.target.value)}
          value={value}
        >
          <option className="bg-gray-200 opacity-30" value="">
            --- {placeholder} ---
          </option>
          {list?.map((l) => {
            return (
              <option value={typeof l == "object" ? l[valueKey] : l}>
                {typeof l == "object"
                  ? capitalizeFirstLetter(l[keyValue])
                  : capitalizeFirstLetter(l)}
              </option>
            );
          })}
        </select>
      </div>
      <div className="text-xs text-red-400">{fieldError}</div>
    </div>
  );
}

export default CustomSelectField;
