function FooterLinkItem({ url, label }) {
  return (
    <a
      href={url}
      target="_blank"
      className="cursor-pointer font-[400] text-[16px] max-lg:text-[14px] max-md:text-[12px] hover:text-[#FF9D21]"
    >
      {label}
    </a>
  );
}

export default FooterLinkItem;
