import React, { useState } from "react";
import { Field, ErrorMessage } from "formik";
import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";
import { getFileTypesFriendly, isFileType } from "../helpers/fileUpload";
import { getImageUrl } from "../helpers/extractors";
import StyledSpan from "./StyledSpan";
import { GENERAL_BLUE_HEX } from "./../constants/texts";
import GeneralModal from "./GeneralModal";
import CustomDocViewer from "./CustomDocViewer";

function CustomInputField({
  label,
  type = "text",
  name,
  placeholder = "",
  required = true,
  disabled = false,
  setFieldValue,
  setFieldError,
  fileTypes,
  inputRef,
  fileCallback,
  extraText,
  readOnly = false,
  selectedFile,
}) {
  const PASSWORD_TEXT = "password";
  const [isHidePassword, setIsHidePassword] = useState(type === PASSWORD_TEXT);
  const [showFileViewer, setShowFileViewer] = useState(false);

  const showPreview = () => {
    return selectedFile && typeof selectedFile != "object";
  };
  return (
    <div className="mb-5 text-left">
      <div className="mb-2 text-[14px] max-md:text-[12px] font-[500]">
        {label}
        {!required && <span className="text-[#FF9D21] ml-2">(Optional)</span>}
        {required && <span className="text-red-300 ml-1">*</span>}
        {extraText && (
          <span className="text-[#FF9D21] ml-2">({extraText})</span>
        )}
      </div>
      <div>
        <div
          className={`${
            !showPreview() ? "border px-3" : ""
          } rounded-md outline-none flex items-center`}
        >
          {type === "file" ? (
            <>
              <input
                name={name}
                type="file"
                id={"file-input"}
                ref={inputRef}
                className="w-full text-[14px] max-md:text-[12px] font-[400] outline-none py-2.5"
                style={{ display: showPreview() ? "none" : "block" }}
                onChange={(event) => {
                  if (isFileType(fileTypes, event.currentTarget.files[0])) {
                    setFieldValue(name, event.currentTarget.files[0]);
                    setFieldValue(name, event.currentTarget.files[0]);
                    setFieldValue("fileText", "input");
                    fileCallback("");
                  } else {
                    inputRef.current.value = null;
                    setFieldValue(name, null);
                    setFieldValue("fileText", "");
                    fileCallback("invalid");
                  }
                }}
              />
              {showPreview() && (
                <div className="flex items-center w-full gap-5">
                  <p
                    className="w-1/4 cursor-pointer"
                    onClick={() => setShowFileViewer(!showFileViewer)}
                  >
                    <StyledSpan
                      text={"View file"}
                      bgColor={`bg-blue-400`}
                      textColor="text-white"
                    />
                  </p>
                  <a className="w-1/4">
                    <label for="file-input" className="cursor-pointer w-full">
                      <StyledSpan
                        text={"Change file"}
                        bgColor={"bg-orange-400"}
                      />
                    </label>
                  </a>
                </div>
              )}
            </>
          ) : (
            <Field
              className="w-full text-[14px] max-md:text-[12px] font-[400] outline-none py-3"
              type={
                type === PASSWORD_TEXT ? (isHidePassword ? type : "text") : type
              }
              name={name}
              placeholder={placeholder}
              disabled={disabled}
              readOnly={readOnly}
            />
          )}

          {type === PASSWORD_TEXT && (
            <>
              {isHidePassword && (
                <IoEyeOffOutline
                  className="text-[20px] max-md:text-[16px] cursor-pointer"
                  onClick={() => setIsHidePassword(false)}
                />
              )}
              {!isHidePassword && (
                <IoEyeOutline
                  className="text-[20px] max-md:text-[16px] cursor-pointer"
                  onClick={() => setIsHidePassword(true)}
                />
              )}
            </>
          )}
        </div>
        <div className="flex">
          <ErrorMessage
            className="text-xs text-red-400"
            name={name}
            component="div"
          />
        </div>
      </div>

      {/* Modal to show file */}
      <GeneralModal
        showCloseButton={true}
        isOpen={showFileViewer}
        onClose={() => setShowFileViewer(false)}
      >
        <div className="h-[40em] text-center">
          <iframe
            width="100%"
            height="100%"
            src={getImageUrl(selectedFile)}
            title="description"
          ></iframe>
        </div>
      </GeneralModal>
    </div>
  );
}

export default CustomInputField;
