import React, { useEffect } from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Layout from "../../commons/Layout";
import { useNavigate } from "react-router-dom";
import useLogout from "../../hooks/useLogout";
import useUser from "../../hooks/useUser";
import CustomBackdrop from "../../commons/CustomBackdrop";
import { removeIntendedRoute } from "../../local_store/intendedRouteLocalStore";

function Logout() {
  const [open, setOpen] = React.useState(true);
  const navigate = useNavigate();

  const { user } = useUser();
  const { logUserOut } = useLogout();

  const logUserOutLocal = () => {
    logUserOut({ id: user?.id, email: user?.email, guid: user?.guid })
      .then((res) => {
        removeIntendedRoute()
      })
      .catch((err) => {})
      .finally(() => {
        navigate("/auth/login");
      });
  };

  useEffect(() => {
    logUserOutLocal();
  }, []);

  return (
    <Layout>
      <CustomBackdrop open={open} text={"You will be logged out shortly..."} />
    </Layout>
  );
}

export default Logout;
