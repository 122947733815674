import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom';
import { registerInstaller } from './../services/installers-service';

function useAddInstaller() {
    const navigate = useNavigate()
    const queryClient = useQueryClient();
    const { mutateAsync: addInstaller, isLoading, error, data } = useMutation({
        mutationFn: (updateData) => registerInstaller(updateData),
        onSuccess: (data) => {

        },
        onError: (error) => {
            // return error
        }
    })
    return {
        addInstaller, isLoading, error, data
    }
}

export default useAddInstaller