import { useQuery } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom';
import { BANKS_QUERY_KEY } from '../constants/queryKeys';
import { getBankList } from '../services/payment-service';

function useBanks() {
    const navigate = useNavigate()
    const { data: banks, isLoading, error } = useQuery({
        queryKey: [BANKS_QUERY_KEY],
        queryFn: async () => {
            const result = await getBankList()
            return result?.data?.data
        },
        onError: (error) => {
            // return error
        }
    })
    return {
        banks, isLoading, error
    }
}

export default useBanks