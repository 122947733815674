import CustomIconWithRoundedBg from "./CustomIconWithRoundedBg";

function CustomIconLabelValue({ icon, label, value, children }) {
  return (
    <div className="cursor-pointer mt-2 flex gap-4 items-center w-full text-ellipsis overflow-hidden ">
      <CustomIconWithRoundedBg>{icon}</CustomIconWithRoundedBg>
      <div className="font-[400] text-[14px]">
        <p className={`text-[12px]`}>{label}</p>
        <p className="">{value}</p>
      </div>
    </div>
  );
}

export default CustomIconLabelValue;
