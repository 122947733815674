import { useQuery } from "@tanstack/react-query";
import { SINGLE_ORDER_QUERY_KEY } from "../constants/queryKeys";
import { fetchOrderById } from "../services/order-services";

function useFetchOrderById(user, id) {
  const {
    data: order,
    isLoading,
    error,
  } = useQuery({
    queryKey: [SINGLE_ORDER_QUERY_KEY, id],
    queryFn: async () => {
      const result = await fetchOrderById(user, id);
      return result?.data?.data;
    },
    enabled: id != null,
    onError: (error) => {
      console.log(error);
    },
  });

  return {
    order,
    isLoading,
    error,
  };
}

export default useFetchOrderById;
