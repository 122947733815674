import React, { useEffect, useState } from "react";
import Layout from "../../commons/Layout";
import { useParams, useNavigate } from "react-router-dom";
import useVerifyEmail from "./../../hooks/useVerifyEmail";
import CustomBackdrop from "../../commons/CustomBackdrop";
import RoundedMdButton from "./../../commons/RoundedMdButton";

function VerifyEmail() {
  const params = useParams();
  const navigate = useNavigate();
  const { token } = params;
  const { verifyUserEmail } = useVerifyEmail();
  const [isLoading, setIsLoading] = useState(true);

  const redirectToSignup = () => {
    navigate("/onboarding");
  };

  useEffect(() => {
    verifyUserEmail({ token })
      .then((res) => {
        navigate("/auth/email-verified", { replace: true });
      })
      .catch((err) => {
        console.log("Error verifying email", err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [token]);

  return (
    <Layout bgClassName="bg-gray-100">
      {isLoading && <CustomBackdrop open={isLoading} text={"Please wait"} />}
      {!isLoading && (
        <div className="flex items-center justify-center h-[80vh] max-md:h-[50vh] py-[1em]">
          <div className="p-5 rounded-lg w-[35%] max-lg:w-3/5 max-md:w-4/5 mx-auto">
            <div className="flex justify-center">
              <img src="/assets/rejected_icon.png" alt="" loading="lazy" />
            </div>
            <div className="text-[28px] max-lg:text-[22px] max-md:text-[16px] font-[600] mt-4 py-2">
              Account Verification failed
            </div>
            <div className="text-[#667185] text-[14px] max-md:text-[12px] font-[500]">
              <div className="my-3 w-full mx-auto text-center">
                Please try again later!!!
              </div>
              <div className="flex items-center justify-center my-5 w-[30%] max-md:w-[50%] mx-auto">
                <RoundedMdButton
                  className="border bg-[#FF9D21] text-white"
                  label={"Got It"}
                  onClick={redirectToSignup}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
}

export default VerifyEmail;
