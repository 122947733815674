import React from "react";

function CustomMiniButton({
  label,
  colorClass = "white",
  bgColorClass = "orange-400",
  onClick,
  className = "py-3 px-7",
  disabled = false,
  type = "button",
}) {
  return (
    <>
      {type === "button" ? (
        <button
          type={type}
          onClick={onClick}
          className={`bg-${bgColorClass} text-center font-[600] text-${colorClass} ${className} rounded-lg  text-[14px] hover:opacity-80`}
          disabled={disabled}
        >
          {label}
        </button>
      ) : (
        <button
          type="submit"
          className={`bg-${bgColorClass} text-center font-[600] text-${colorClass} ${className} rounded-lg  text-[14px] hover:opacity-80`}
          disabled={disabled}
        >
          {label}
        </button>
      )}
    </>
  );
}

export default CustomMiniButton;
