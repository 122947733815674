import { useQuery } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom';
import { COLORS_QUERY_KEY } from '../constants/queryKeys';
import { getColors } from '../services/colors-service';

function useColors() {
    const navigate = useNavigate()
    const { data: colors, isLoading, error } = useQuery({
        queryKey: [COLORS_QUERY_KEY],
        queryFn: async () => {
            const result = await getColors()
            return result?.data?.data
        },
        onError: (error) => {
            // return error
        }
    })
    return {
        colors, isLoading, error
    }
}

export default useColors