import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom';
import { MAKER_QUERY_KEY } from '../constants/queryKeys';
import { registerMaker } from './../services/makers-service';

function useAddMaker() {
    const navigate = useNavigate()
    const queryClient = useQueryClient();
    const { mutateAsync: addMaker, isLoading, error, data } = useMutation({
        mutationFn: (updateData) => registerMaker(updateData),
        onSuccess: (data) => {

        },
        onError: (error) => {
            // return error
        }
    })
    return {
        addMaker, isLoading, error, data
    }
}

export default useAddMaker