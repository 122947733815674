import React from "react";

function RadioItem({
  onClick,
  selectedValue,
  value,
  label,
  groupName,
  padding = "p-4",
}) {
  const selected = selectedValue == value;
  return (
    <div
      onClick={onClick}
      className={`${
        selected ? "border-[#318EC9]" : ""
      } ${padding} text-[14px] max-md:text-[12px] font-[500] border border-[2px] flex gap-5 max-md:gap-3 item-center w-full rounded-lg hover:border-[#318EC9] cursor-pointer`}
    >
      <input
        checked={selected}
        type="radio"
        name={groupName}
        className="w-[24px] h-[24px] max-md:w-[16px] max-md:h-[16px]"
      />
      {label}
    </div>
  );
}

export default RadioItem;
