import { REMOVE_MESSAGE_INFO, SET_MESSAGE_INFO } from './types';

export const setMessageInfo = ({ message, isError }) => {
    return {
        type: SET_MESSAGE_INFO,
        payload: { message, isError }
    }
}

export const removeMessageInfo = () => {
    return {
        type: REMOVE_MESSAGE_INFO
    }
}