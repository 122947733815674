export const isFileType = (fileTypes, file) => {
    if (fileTypes && file) {
        if (fileTypes.includes(file.type)) {
            return true;
        }
    } else {
        return false
    }
}

export const getFileTypesFriendly = (fileTypes) => {
    let text = "";
    if (fileTypes) {
        if (typeof fileTypes === "string") {
            text = fileTypes.split("/")[1]
        } else {
            for (const f of fileTypes) {
                if (f.includes("vnd")) {
                    text += "docx" + ", "
                } else {
                    text += f.split("/")[1] + ", "
                }
            }
            text = text.substring(0, text.length - 2);
        }
    }


    return text;
}