import React from "react";

function NavButton({ label, className, children, selected, onClick }) {
  return (
    <button
      className={`${
        selected ? "bg-gray-200" : ""
      } border px-3 py-2 rounded-md font-[500] flex ${
        children ? "gap-1" : ""
      } items-center text-[13px] max-md:text-[11px] ${className}`}
      onClick={onClick}
    >
      <span>{children}</span>
      <span>{label}</span>
    </button>
  );
}

export default NavButton;
