
import { TOGGLE_RADIO_VALUE } from './types';

export function radioGroupHandler(state, action) {
    let newState;
    switch (action.type) {
        case TOGGLE_RADIO_VALUE:
            let toggledValue = action.payload;

            newState = { ...state, radioValue: toggledValue }

            break;
        default:
            newState = { ...state };
            break;
    }
    return newState;
}