import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import Layout from "../../commons/Layout";
import RoundedMdButton from "../../commons/RoundedMdButton";
import ViewAllButton from "../../commons/ViewAllButton";
import { MY_ORDERS } from "../../constants/data";

function EmailVerified({}) {
  const params = useParams();
  const ref = params?.reference;

  const navigate = useNavigate();

  const redirectToLogin = () => {
    navigate("/auth/login");
  };

  return (
    <Layout bgClassName="bg-gray-100">
      <div className="flex items-center justify-center h-[80vh] max-md:h-[50vh] py-[1em]">
        <div className="p-5 rounded-lg w-[35%] max-lg:w-3/5 max-md:w-4/5 mx-auto">
          <div className="flex justify-center">
            <img src="/assets/success-icon.png" alt="" loading="lazy" />
          </div>
          <div className="text-[28px] max-lg:text-[22px] max-md:text-[16px] font-[600] mt-4 py-2">
            Account verification successful
          </div>
          <div className="text-[#667185] text-[14px] max-md:text-[12px] font-[500]">
            <div className="my-3 w-full mx-auto text-center">
              Please click the button to login to your account
            </div>
            <div className="flex items-center justify-center my-5 w-[30%] max-md:w-[50%] mx-auto">
              <RoundedMdButton
                className="border bg-[#FF9D21] text-white"
                label={"Login"}
                onClick={redirectToLogin}
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default EmailVerified;
