import "./App.css";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
  useLocation,
} from "react-router-dom";
import Login from "./pages/auth/Login";
import Logout from "./pages/auth/Logout";
import Signup from "./pages/auth/Signup";
import ResetPassword from "./pages/auth/ResetPassword";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Account from "./pages/account/Account";
import useUser from "./hooks/useUser";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { useEffect, useLayoutEffect } from "react";
import { getIntendedRoute } from "./local_store/intendedRouteLocalStore";
import Onboarding from "./pages/vendor_onboarding/Onboarding";
import OnboardingTwo from "./pages/vendor_onboarding/seller/OnboardingTwo";
import Products from "./pages/vendor_dashboard/products";
import Orders from "./pages/vendor_dashboard/orders/index";
import Payments from "./pages/vendor_dashboard/payments/index";
import AddProduct from "./pages/vendor_dashboard/add_products/index";
import VerifyEmail from "./pages/auth/VerifyEmail";
import RegistrationInitiation from "./pages/auth/RegistrationInitiation";
import EmailVerified from "./pages/auth/EmailVerified";
import OnboardingCompleted from "./pages/vendor_onboarding/OnboardingCompleted";
import { PENDING } from "./constants/texts";
import InstallerRegistrationCompleted from "./pages/vendor_onboarding/installer/InstallerRegistrationCompleted";
import MakerRegistrationCompleted from "./pages/vendor_onboarding/maker/MakerRegistrationCompleted";
import OrderDetailsPage from "./pages/vendor_dashboard/orders/ordersDetails";
import ResetPasswordToken from "./pages/auth/ResetPasswordToken";

function App() {
  const queryClient = new QueryClient();

  const routeListOrder = [
    "/auth/registration-sent",
    "/complete-onboarding",
    "/onboarding-completed",
    "/dashboard/products",
  ];

  const actualPermissions = ["status", "onboardingCompleted", "verified"];

  const ProtectedRoute = ({ children, permissions = [] }) => {
    const location = useLocation();
    useLayoutEffect(() => {
      document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);

    const { user } = useUser();

    if (!user) {
      return <Navigate to="/auth/login" replace />;
    }

    let permitted = true;
    let index = 0;

    //first go through the actual permissions
    for (const permission of actualPermissions) {
      permitted = user[permission] === true;
      if (permitted) {
        ++index;
      }
    }

    if (!permitted) {
      //reset states
      permitted = true;
      index = 0;
      for (const permission of permissions) {
        permitted = user[permission] === true;
        if (permitted) {
          ++index;
        }
      }
    }

    if (!permitted) {
      return <Navigate to={routeListOrder[index]} />;
    } else {
      return <>{children}</>;
    }
  };

  const AuthenticatedRoute = ({ children }) => {
    const location = useLocation();
    useLayoutEffect(() => {
      document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);

    return <>{children}</>;
  };

  const OnboardingCompletedRoute = ({ children }) => {
    const { user } = useUser();
    const location = useLocation();
    useLayoutEffect(() => {
      document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);

    if (!user) {
      return <Navigate to="/auth/login" replace />;
    }

    if (
      user.onboardingCompleted &&
      user.verificationStatus?.toLowerCase() === PENDING?.toLowerCase()
    ) {
      return <>{children}</>;
    }

    const intendedRoute = getIntendedRoute("/dashboard/products");

    return <Navigate to={intendedRoute} replace />;
  };

  /// Zoho sale IQ script
  useEffect(() => {
    // Create the first inline script element
    const zohoScript = document.createElement("script");
    zohoScript.text = `window.$zoho=window.$zoho || {}; $zoho.salesiq=$zoho.salesiq||{ready:function(){}};`;
    document.body.appendChild(zohoScript);

    // Create the second script element with the Zoho SalesIQ widget URL
    const script = document.createElement("script");
    script.id = "zsiqscript";
    script.src =
      "https://salesiq.zohopublic.com/widget?wc=siq1eadd48df997eef665b439afa82c868696429f6768a1a15a26d692f6e7fddf16";
    script.defer = true;
    document.body.appendChild(script);

    // Clean up the scripts when the component unmounts
    return () => {
      document.body.removeChild(zohoScript);
      document.body.removeChild(script);
    };
  }, []);

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Navigate to="/dashboard/products" replace />,
    },
    {
      path: "/onboarding",
      element: (
        <AuthenticatedRoute>
          <Onboarding />
        </AuthenticatedRoute>
      ),
    },
    {
      path: "/complete-onboarding",
      element: (
        <ProtectedRoute permissions={["status"]}>
          <OnboardingTwo />
        </ProtectedRoute>
      ),
    },
    {
      path: "/edit-business-profile",
      element: (
        <ProtectedRoute>
          <OnboardingTwo editing={true} />
        </ProtectedRoute>
      ),
    },
    {
      path: "/onboarding-completed",
      element: (
        <OnboardingCompletedRoute>
          <OnboardingCompleted />
        </OnboardingCompletedRoute>
      ),
    },
    {
      path: "/auth/login",
      element: (
        <AuthenticatedRoute>
          <Login />
        </AuthenticatedRoute>
      ),
    },
    {
      path: "/auth/logout",
      element: <Logout />,
    },
    {
      path: "/auth/signup",
      element: (
        <AuthenticatedRoute>
          <Signup />
        </AuthenticatedRoute>
      ),
    },
    {
      path: "/installer/complete",
      element: (
        <>
          <InstallerRegistrationCompleted />
        </>
      ),
    },
    {
      path: "/maker/complete",
      element: (
        <>
          <MakerRegistrationCompleted />
        </>
      ),
    },
    {
      path: "/auth/registration-sent",
      element: (
        <AuthenticatedRoute>
          <RegistrationInitiation />
        </AuthenticatedRoute>
      ),
    },
    {
      path: "/auth/email-verified",
      element: (
        <AuthenticatedRoute>
          <EmailVerified />
        </AuthenticatedRoute>
      ),
    },
    {
      path: "/auth/forgot-password",
      element: <ResetPassword />,
    },
    {
      path: "/auth/reset-password/:token",
      element: <ResetPasswordToken />,
    },
    {
      path: "/dashboard/products",
      element: (
        <ProtectedRoute
          permissions={["status", "onboardingCompleted", "verified"]}
        >
          <Products />
        </ProtectedRoute>
      ),
    },
    {
      path: "/dashboard/products/:slug",
      element: (
        <ProtectedRoute
          permissions={["status", "onboardingCompleted", "verified"]}
        >
          <AddProduct editing={true} />
        </ProtectedRoute>
      ),
    },
    {
      path: "/auth/verify/:token",
      element: <VerifyEmail />,
    },
    {
      path: "/auth/email-verifed",
      element: <EmailVerified />,
    },
    {
      path: "/dashboard/orders",
      element: (
        <ProtectedRoute
          permissions={["status", "onboardingCompleted", "verified"]}
        >
          <Orders />
        </ProtectedRoute>
      ),
    },
    {
      path: "/dashboard/orders/:id",
      element: (
        <ProtectedRoute
          permissions={["status", "onboardingCompleted", "verified"]}
        >
          <OrderDetailsPage />
        </ProtectedRoute>
      ),
    },
    {
      path: "/dashboard/payments",
      element: (
        <ProtectedRoute
          permissions={["status", "onboardingCompleted", "verified"]}
        >
          <Payments />
        </ProtectedRoute>
      ),
    },
    {
      path: "/dashboard/add-product",
      element: (
        <ProtectedRoute
          permissions={["status", "onboardingCompleted", "verified"]}
        >
          <AddProduct />
        </ProtectedRoute>
      ),
    },
    {
      path: "/my-account",
      element: (
        <>
          <ProtectedRoute
            permissions={["status", "onboardingCompleted", "verified"]}
          >
            <Account />
          </ProtectedRoute>
        </>
      ),
    },
  ]);

  const clientId = process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID;

  return (
    <div className="App">
      <GoogleOAuthProvider clientId={clientId}>
        <QueryClientProvider client={queryClient}>
          <RouterProvider router={router}></RouterProvider>
        </QueryClientProvider>
      </GoogleOAuthProvider>
    </div>
  );
}

export default App;
