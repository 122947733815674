import React from "react";
import { LuSearchX } from "react-icons/lu";

function NotFoundSVG() {
  return (
    <div>
      <LuSearchX className="text-[6em] text-gray-400" />
    </div>
  );
}

export default NotFoundSVG;
