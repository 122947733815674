export const getNetworkErrorMessage = (err) => {
    console.log("error =========", err)
    if (err?.response?.data?.errors?.name == "SequelizeUniqueConstraintError") {
        console.log(err?.response?.data?.errors?.errors[0]?.message ?? err?.message)
        throw Error(err?.response?.data?.errors?.errors[0]?.message ?? err?.message)
    }
    else if (err?.code === "ERR_BAD_REQUEST") {
        throw Error(err.response.data.errors[0])
    } else if (err?.code === "ERR_NETWORK") {
        throw Error(err?.message)
    }
    else if (err?.code === "ERR_BAD_RESPONSE") {
        throw Error(err.response.data.errors[0] ?? err?.message)
    }
    else {
        throw new Error(err);
    }
}