import axiosInstance from "../utils/axiosInstance";
import { getNetworkErrorMessage } from "./../helpers/network";

export const getVendors = async (data) => {
  return axiosInstance(`/vendors`, "GET", data, data?.accessToken)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      getNetworkErrorMessage(err);
    });
};

export const getVendor = async (data) => {
  return axiosInstance(`/vendors`, "POST", data, data.accessToken)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      // return err
      getNetworkErrorMessage(err);
    });
};

export const vetVendor = async (data) => {
  return axiosInstance(`/vendors/vet-business`, "POST", data, data?.accessToken)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      getNetworkErrorMessage(err);
    });
};

export const vetUnregisteredVendor = async (data) => {
  return axiosInstance(
    `/vendors/vet-unregistered-business`,
    "POST",
    data,
    data?.accessToken
  )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      getNetworkErrorMessage(err);
    });
};

export const updateVendor = async (data) => {
  return axiosInstance(`/vendors/${data.id}`, "PUT", data, data?.accessToken)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      getNetworkErrorMessage(err);
    });
};
