import React, { useState } from "react";
import PageTitleBig from "../../../commons/PageTitleBig";
import { INSTALLER, MAKER, SELLER } from "../../../constants/texts";
import PartnerTypeCard from "./PartnerTypeCard";

function PartnerType({ selected, setSelected }) {
  return (
    <div className="text-left">
      <PageTitleBig title="Partner Type" className="w-full text-left" />
      <p className="text-[17px] max-md:text-[15px] max-sm:text-[13px] text-[#828282] mt-[-1.5em] ">
        What is your primary role on Wudify? Please select
      </p>
      <div className="flex max-md:flex-col justify-between gap-6 mt-[1.5em]">
        <PartnerTypeCard
          title="Seller"
          description="I want to sell on Wudify"
          value={SELLER}
          selected={selected}
          onClick={setSelected}
        />
        <PartnerTypeCard
          title="Maker"
          description="I manufacture furnitures"
          value={MAKER}
          selected={selected}
          onClick={setSelected}
        />
        <PartnerTypeCard
          title="Installer"
          description="I install furnitures"
          value={INSTALLER}
          selected={selected}
          onClick={setSelected}
        />
      </div>
    </div>
  );
}

export default PartnerType;
