import React from "react";

function PartnerTypeCard({ title, description, selected, value, onClick }) {
  return (
    <div
      onClick={() => onClick(value)}
      className={`p-4 rounded-lg border w-full flex items-center gap-6 cursor-pointer border-[2px] hover:border-[#318EC9] ${
        selected == value ? "border-[#318EC9]" : ""
      }`}
    >
      <div>
        <input
          className="w-[20px] h-[20px]"
          type="radio"
          checked={selected == value}
        />
      </div>
      <div>
        <div className="font-[500] text-[17px] max-md:text-[15px] max-sm:text-[13px] text-[#344054]">
          {title}
        </div>
        <div className="font-[400] text-[17px] max-md:text-[15px] max-sm:text-[13px] text-[#667085]">
          {description}
        </div>
      </div>
    </div>
  );
}

export default PartnerTypeCard;
