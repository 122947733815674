import { addPaginationUrlQuery } from "../helpers/extractors";
import { getNetworkErrorMessage } from "../helpers/network";
import axiosInstance from "../utils/axiosInstance";



export const getTypes = async (data) => {
    return axiosInstance(`/types`, "GET", data).then(response => {
        return response;
    })
}

export const fetchTypesBySubCategory = async (slug) => {
    return axiosInstance(`/types/by-subcategory/${slug}`, "GET", null, null).then(response => {
        return response;
    }).catch(err => {
        // return err
        getNetworkErrorMessage(err)
    })
}

export const fetchProductTypeBySlug = async (slug, pageSize, pageNumber, productColors, productBrands, productTypes, productPrices, productSortFilter) => {
    let newUrl = addPaginationUrlQuery(`/types/by/${slug}`, pageSize, pageNumber)
    return axiosInstance(newUrl + `&productColors=${productColors}&productBrands=${productBrands}&productTypes=${productTypes}&productPrices=${productPrices}&productSortFilter=${productSortFilter}`, "GET", null, null).then(response => {
        return response;
    }).catch(err => {
        // return err
        getNetworkErrorMessage(err)
    })
}


