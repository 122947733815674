import React, { useReducer, useRef, useState } from "react";
import { Formik } from "formik";
import { Form } from "formik";
import CustomInputField from "../../../commons/CustomInputField";
import HeadingAndDetails from "../HeadingAndDetails";
import RoundedMdButton from "../../../commons/RoundedMdButton";
import CustomMultiSelectField from "../../../commons/CustomMultiSelectField";
import { listCreationReducer } from "./../../../reducers/listCreationReducer/index";
import { addItemToList } from "./../../../reducers/listCreationReducer/actions";
import { getFileTypesFriendly } from "../../../helpers/fileUpload";
import { validEmail, validPhoneNumber } from "../../../helpers/validator";
import useStates from "./../../../hooks/useStates";
import useTowns from "./../../../hooks/useTowns";
import useTypes from "./../../../hooks/useTypes";
import CustomSelectField from "./../../../commons/CustomSelectField";
import CustomTextField from "./../../../commons/CustomTextField";
import ConfirmationModal from "../../../commons/modals/ConfirmationModal";
import { imageAndDocFileTypes } from "../../../constants/data";
import { MEANS_OF_ID } from "./../../../constants/data";
import useCustomInfoDisplay from "./../../../hooks/useCustomInfoDisplay";
import useAddInstaller from "./../../../hooks/useAddInstaller";
import { useNavigate } from "react-router-dom";
import CustomBackdrop from "../../../commons/CustomBackdrop";

function InstallerContactDetails({ setStepNumber }) {
  const [productTypesState, productTypesDispatch] = useReducer(
    listCreationReducer,
    { list: [] }
  );

  const navigate = useNavigate();

  const { addInstaller } = useAddInstaller();

  const { setInfoDetails, InfoDisplayComponent } = useCustomInfoDisplay();

  const { types } = useTypes();
  const [fileInput, setFileInput] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const idFileRef = useRef();

  const { states: stateList } = useStates();
  const [state, setState] = useState("");
  const [region, setRegion] = useState("");
  const { towns } = useTowns(state);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleProductTypesChange = (value) => {
    productTypesDispatch(addItemToList(value));
  };

  return (
    <div className="mt-6">
      <HeadingAndDetails
        title={"Contact Details"}
        description={"Kindly complete the form below"}
      />
      <div className="mt-2"></div>
      <Formik
        initialValues={{
          email: "",
          phoneNumber: "",
          firstName: "",
          lastName: "",
          address: "",
          state: "",
          region: "",
          meansOfIdentification: "",
          meansOfIdentificationFile: "",
          typeOfInstallation: "",
        }}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setIsSubmitting(true);
          if (productTypesState.list.length < 1) {
            setInfoDetails({
              message: "Please select one or more installation types",
              isError: true,
            });
            setSubmitting(false);

            //This would control the back drop loader
            setIsSubmitting(false);
            return;
          }
          addInstaller({
            ...values,
            containsFile: true,
            productTypes: productTypesState.list.toString(),
          })
            .then((res) => {
              setInfoDetails({ message: res?.data?.message, isError: false });
              if (idFileRef?.current) {
                idFileRef.current.value = null;
              }
              values.meansOfIdentification = null;
              values.meansOfIdentificationFile = "";
              values.state = null;
              values.region = null;
              setState(null);
              setRegion(null);
              resetForm();
              navigate("/installer/complete");
            })
            .catch((err) => {
              setInfoDetails({ message: err?.message, isError: true });
            })
            .finally(() => {
              setSubmitting(false);
            });
        }}
        validate={(values) => {
          const errors = {};

          if (values.meansOfIdentificationFile === null) {
            setFileInput("");
            setOpenModal(true);
          }

          if (!values.email) {
            errors.email = "Email is required";
          } else if (!validEmail(values.email)) {
            errors.email = "Invalid email address";
          }

          if (!values.phoneNumber) {
            errors.phoneNumber = "Phone number is required";
          } else if (!validPhoneNumber(values.phoneNumber)) {
            errors.phoneNumber = "Invalid phone number";
          }
          if (!values.firstName) {
            errors.firstName = "First name is required";
          }

          if (!values.lastName) {
            errors.lastName = "Last name is required";
          }
          if (!values.address) {
            errors.address = "Address is required";
          }
          if (!values.state || values.state == "") {
            setState("");
            errors.state = "State is required";
          } else {
            setState(values.state);
          }
          if (!values.region) {
            errors.region = "Region is required";
          } else {
            setRegion(values.region);
          }

          if (!values.meansOfIdentification) {
            errors.meansOfIdentification =
              "Means of identification is required";
          }

          if (!values.meansOfIdentificationFile) {
            errors.meansOfIdentificationFile =
              "Means of identification file is required";
          }

          return errors;
        }}
      >
        {({ isSubmitting, setFieldError, setFieldValue, values }) => (
          <Form>
            <div className="flex items-center gap-4 max-sm:gap-0 max-sm:flex-col">
              <div className="w-full">
                <CustomInputField
                  name={"firstName"}
                  required={true}
                  placeholder="Enter first name"
                  label={"First Name"}
                />
              </div>
              <div className="w-full">
                <CustomInputField
                  name={"lastName"}
                  required={true}
                  placeholder="Enter last name"
                  label={"Last Name"}
                />
              </div>
              <div className="w-full">
                <CustomInputField
                  name={"phoneNumber"}
                  required={true}
                  placeholder="Enter phone number"
                  label={"Phone Number"}
                />
              </div>
            </div>
            <div className="flex items-start gap-4 max-sm:gap-0 max-sm:flex-col">
              <div className="w-full">
                <CustomInputField
                  name={"email"}
                  required={true}
                  placeholder="Enter your email"
                  label={"Email"}
                />
              </div>

              <div className="w-full">
                <CustomSelectField
                  name={"state"}
                  required={true}
                  placeholder="Select state"
                  type="text"
                  label={"State"}
                  valueKey={"state_code"}
                  keyValue={"name"}
                  selectedValue={state}
                  list={stateList?.data ?? []}
                  onChange={(value) => setFieldValue("state", value)}
                  setFieldError={setFieldError}
                />
              </div>
              <div className="w-full">
                {!state && (
                  <CustomInputField
                    readOnly={true}
                    placeholder="Select region"
                    name="region"
                    type="text"
                    label={"Region"}
                  />
                )}
                {state && (
                  <CustomSelectField
                    name={"region"}
                    required={true}
                    placeholder="Select region"
                    type="text"
                    label={"Region"}
                    valueKey={"name"}
                    keyValue={"name"}
                    selectedValue={region}
                    list={!towns?.data?.message ? towns?.data : []}
                    onChange={(value) => setFieldValue("region", value)}
                    setFieldError={setFieldError}
                    disabled={state == ""}
                  />
                )}
              </div>
            </div>

            <div className="flex items-start gap-4">
              <div className="w-full">
                <CustomTextField
                  name={"address"}
                  required={true}
                  placeholder="Enter your address"
                  label={"Address"}
                />
              </div>
            </div>

            <HeadingAndDetails title={"Identity details"} />

            <div className="flex items-center gap-6 mt-3 max-sm:gap-0 max-sm:flex-col">
              <div className="w-full">
                <CustomSelectField
                  name={"meansOfIdentification"}
                  required={true}
                  placeholder="Select means of identification"
                  label={"Means of Identification"}
                  list={MEANS_OF_ID}
                  selectedValue={values.meansOfIdentification}
                  onChange={(value) =>
                    setFieldValue("meansOfIdentification", value)
                  }
                />
              </div>
              <div className="w-full">
                <CustomInputField
                  name={"meansOfIdentificationFile"}
                  required={true}
                  label={"Attach Means of Identification"}
                  type="file"
                  fileTypes={imageAndDocFileTypes}
                  setFieldValue={setFieldValue}
                  setFieldError={setFieldError}
                  inputRef={idFileRef}
                  fileCallback={setFileInput}
                />
              </div>
            </div>

            <HeadingAndDetails title={"Installation details"} />
            <div className="flex items-center gap-6 mt-3 max-sm:gap-0 max-sm:flex-col">
              <div className="w-full">
                <CustomMultiSelectField
                  name={"typesOfInstallation"}
                  required={true}
                  placeholder="Select your installation types"
                  label={"Type of Installation"}
                  selectedValueList={productTypesState.list}
                  list={types ?? []}
                  valueKey="name"
                  onChange={handleProductTypesChange}
                />
              </div>
              <div className="w-full"></div>
            </div>

            <div className="flex justify-between mb-8 mt-6">
              <div className="w-[20%]">
                <RoundedMdButton
                  label={"Previous"}
                  disabled={isSubmitting}
                  onClick={() => setStepNumber(0)}
                />
              </div>
              <div className="w-[20%]">
                <RoundedMdButton
                  label={isSubmitting ? "Please wait..." : "Submit"}
                  type="submit"
                  disabled={isSubmitting}
                  className="bg-[#FF9D21] font-[500] text-[16px] text-white"
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>

      {InfoDisplayComponent}

      {isSubmitting && (
        <CustomBackdrop open={isSubmitting} text="Please wait..." />
      )}

      <ConfirmationModal
        isOpen={openModal && fileInput != ""}
        type={"warning"}
        title={"File upload error"}
        description={""}
        subText={
          "Invalid file type. Acceptable types (" +
          getFileTypesFriendly(imageAndDocFileTypes) +
          ")"
        }
        onClose={() => {
          setOpenModal(false);
        }}
      >
        <div className="my-5">
          <RoundedMdButton
            label={"OK"}
            onClick={() => {
              setOpenModal(false);
            }}
          />
        </div>
      </ConfirmationModal>
    </div>
  );
}

export default InstallerContactDetails;
