import { DOCX_FILE_TYPE, JPEG_FILE_TYPE, JPG_FILE_TYPE, PDF_FILE_TYPE, PNG_FILE_TYPE, WEBP_FILE_TYPE } from "./texts"

export const MY_PROFILE = "my-profile"
export const MY_BUSINESS_PROFILE = "my-business-profile"
export const MY_ORDERS = "my-orders"
export const PENDING_REVIEWS = "pending-reviews"
export const SAVED_ITEMS = "saved-items"
export const ADDRESS_BOOK = "address-book"
export const CHANGE_PASSWORD = "change-password"
export const NEW_ADDRESS = "new-address"
export const EDIT_ADDRESS = "edit-address"
export const DELETE_ACCOUNT = "delete-account"
export const PAYMENT_ON_DELIVERY = "Payment on delivery"
export const DEBIT_CREDIT_CARD = "Debit or Credit card"
export const WALLET_BALANCE = "Wallet balance"

export const paymentTypes = [
    { value: DEBIT_CREDIT_CARD, label: "Debit or Credit card" },
    { value: PAYMENT_ON_DELIVERY, label: "Payment on delivery" },
    { value: WALLET_BALANCE, label: "Wallet balance" },
];

export const imageOnlyFileTypes = [
    PNG_FILE_TYPE,
    JPG_FILE_TYPE,
    JPEG_FILE_TYPE,
    WEBP_FILE_TYPE,
];


export const imageAndDocFileTypes = [
    PNG_FILE_TYPE,
    JPG_FILE_TYPE,
    JPEG_FILE_TYPE,
    WEBP_FILE_TYPE,
    PDF_FILE_TYPE,
    DOCX_FILE_TYPE,
];

export const MEANS_OF_ID = [
    "National ID",
    "Driver's License",
    "Permanent Voter's Card",
]