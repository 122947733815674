import React from "react";
import CustomBackButton from "../../../commons/CustomBackButton";
import OnboardingSteps from "../../../commons/OnboardingSteps";
import PageTitleBig from "../../../commons/PageTitleBig";

const stepList = [
  "Partner type",
  "Sign up",
  "Product type",
  "Business vetting",
];

function OnboardingNavigator({
  stepNumber,
  setStepNumber,
  showBackButtonIndex = 0,
  complete = false,
  editing = false,
}) {
  return (
    <div>
      <div className="flex justify-between mb-8 items-center gap-4 max-md:gap-2 max-md:flex-col">
        {!complete && (
          <PageTitleBig
            marginBottomClass="!mb-0"
            title="Let's get your partner account ready"
          />
        )}
        {complete && !editing && (
          <PageTitleBig
            marginBottomClass="!mb-0"
            title="Let's complete your partner account setup"
          />
        )}
        {editing && (
          <PageTitleBig
            marginBottomClass="!mb-0"
            title="Let's edit your partner account setup"
          />
        )}
        {stepNumber > showBackButtonIndex && (
          <div className="">
            <CustomBackButton
              onClick={() =>
                setStepNumber((prev) =>
                  prev > showBackButtonIndex ? --prev : showBackButtonIndex
                )
              }
            />
          </div>
        )}
      </div>

      <OnboardingSteps
        widthClass="w-3/4 max-md:w-[90%]"
        stepList={stepList}
        currentIndex={stepNumber}
      />
    </div>
  );
}

export default OnboardingNavigator;
