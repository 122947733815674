import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom';
import { USER_QUERY_KEY } from '../constants/queryKeys';
import { googleLogin } from "../services/auth-service";

function useGoogleLogin() {
    const navigate = useNavigate()
    const queryClient = useQueryClient();
    const { mutateAsync: googleLogUserIn, isLoading, error, data } = useMutation({
        mutationFn: (loginData) => googleLogin(loginData),
        onSuccess: (data) => {
            queryClient.setQueryData([USER_QUERY_KEY], data?.data?.data)
            navigate("/my-account")
        },
        onError: (error) => {
            // return error
        }
    })
    return {
        googleLogUserIn, isLoading, error, data
    }
}

export default useGoogleLogin