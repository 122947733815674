export const DECOR_TIPS_QUERY_KEY = "decor-tips";
export const USER_QUERY_KEY = "user";
export const PRODUCT_CATEGORIES_QUERY_KEY = "product-categories";
export const PRODUCT_CATEGORY_QUERY_KEY = "product-category";
export const PRODUCTS_QUERY_KEY = "products";
export const COLORS_QUERY_KEY = "colors";
export const BANKS_QUERY_KEY = "banks";
export const STATES_QUERY_KEY = "states";
export const COUNTRIES_QUERY_KEY = "countries";
export const TOWNS_QUERY_KEY = "towns";
export const MY_WUDIFY_QUERY_KEY = "mywudifys";
export const VENDORS_QUERY_KEY = "vendors";
export const TYPES_QUERY_KEY = "types";
export const SEARCHED_PRODUCTS_QUERY_KEY = "searched-products";
export const FILTERED_PRODUCTS_QUERY_KEY = "filtered-products";
export const PRODUCT_QUERY_KEY = "product";
export const TYPES_BY_SUBCAT_SLUG_QUERY_KEY = "types-subcat-slug";
export const PRODUCT_SUB_CATEGORIES_QUERY_KEY = "product-subcategories";
export const PRODUCT_SUB_CATEGORY_QUERY_KEY = "product-subcategory";
export const PRODUCT_TYPE_QUERY_KEY = "product-type";
export const ADDRESS_QUERY_KEY = "address";
export const CART_ITEM_QUERY_KEY = "cart-items";
export const ORDER_ITEMS_QUERY_KEY = "order-items";
export const SINGLE_ORDER_QUERY_KEY = "single-order";
export const VENDOR_ITEMS_QUERY_KEY = "vendor-order-items";
export const PENDING_REVIEWS_QUERY_KEY = "pending-reviews";
export const SUBSCRIPTION_ITEM_QUERY_KEY = "subscription";
export const BUY_NOW_ITEM_QUERY_KEY = "buy-now";
export const WISH_LIST_QUERY_KEY = "wish-list";
export const SELECTED_ADDRESS_QUERY_KEY = "selected-address";
export const FURNITURE_GUIDES_QUERY_KEY = "furniture-guides";
export const FURNITURE_GUIDE_QUERY_KEY = "furniture-guide";
export const SINGLE_VENDOR_QUERY_KEY = "single-vendor";
export const SINGLE_PRODUCT_QUERY_KEY = "single-product";
