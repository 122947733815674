import { useQuery } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom';
import { PRODUCT_QUERY_KEY } from '../constants/queryKeys';
import { fetchProductBySlug } from '../services/products-services';

function useProduct(user, slug) {
    const navigate = useNavigate();
    const { data: product, isLoading, error } = useQuery({
        queryKey: [PRODUCT_QUERY_KEY, slug],
        queryFn: async () => {
            const result = await fetchProductBySlug(user, slug)
            return result?.data?.data
        },
        enabled: slug != null,
        onError: (error) => {
            // return error
        }
    })
    return {
        product, isLoading, error
    }
}

export default useProduct