import { useQuery } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom';
import { TYPES_QUERY_KEY } from '../constants/queryKeys';
import { getTypes } from '../services/product_types-services';

function useTypes() {
    const navigate = useNavigate()
    const { data: types, isLoading, error } = useQuery({
        queryKey: [TYPES_QUERY_KEY],
        queryFn: async () => {
            const result = await getTypes()
            return result?.data?.data
        },
        onError: (error) => {
            // return error
        }
    })
    return {
        types, isLoading, error
    }
}

export default useTypes