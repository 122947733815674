import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { USER_QUERY_KEY } from '../constants/queryKeys'
import { logout } from "../services/auth-service";


function useLogout() {
    const navigate = useNavigate()
    const queryClient = useQueryClient();
    const { mutateAsync: logUserOut, isLoading, error, data } = useMutation({
        mutationFn: (logoutData) => logout(logoutData),
        onSuccess: (data) => {
            queryClient.setQueryData([USER_QUERY_KEY], null)
            navigate("/auth/logout")
        },
        onError: (error) => {
            // return error
        }
    })
    return {
        logUserOut, isLoading, error, data
    }
}
// function useLogout() {
//     const queryClient = useQueryClient()
//     const navigate = useNavigate()

//     const logUserOut = useCallback(() => {
//         queryClient.setQueryData([USER_QUERY_KEY], null)
//         navigate("/auth/logout")
//     }, [navigate, queryClient])
//     return {
//         logUserOut
//     }
// }

export default useLogout