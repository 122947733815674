import { REMOVE_MESSAGE_INFO, SET_MESSAGE_INFO } from "./types";



export function infoDisplayReducer(state, action) {
    let newState;
    switch (action.type) {
        case SET_MESSAGE_INFO:
            let details = action.payload
            newState = { ...state, message: details.message, isError: details.isError, display: true };
            break;
        case REMOVE_MESSAGE_INFO:
            newState = { ...state, message: "", isError: false, display: false };
            break;
        default:
            newState = { ...state };
            break;
    }
    return newState;
}