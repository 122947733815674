function CustomNavTabs({ tabs = [], selectedTab, setSelectedTab }) {
  const getActive = (tab) => {
    return selectedTab == tab;
  };

  return (
    <div className="flex p-1 items-start bg-white px-3 shadow-sm rounded-md gap-2 border border-lightGrey">
      {tabs.map((tab) => {
        return (
          <div
            onClick={() => setSelectedTab(tab)}
            className="cursor-pointer p-2 rounded-md"
            style={{
              backgroundColor: getActive(tab) ? "#FF9D21" : "",
              color: getActive(tab) ? "white" : "",
            }}
          >
            {tab}
          </div>
        );
      })}
    </div>
  );
}

export default CustomNavTabs;
