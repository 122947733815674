import { getNetworkErrorMessage } from "../helpers/network";
import axiosInstance from "../utils/axiosInstance";


export const registerInstaller = async (data) => {
    return axiosInstance(`/installers`, "POST", data).then(response => {
        return response;
    }).catch(err => {
        getNetworkErrorMessage(err)
    })
}