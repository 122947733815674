import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import Layout from "../../commons/Layout";

function OnboardingCompleted({}) {
  return (
    <Layout bgClassName="bg-gray-100">
      <div className="flex items-center justify-center h-[80vh] max-md:h-[50vh] pt-[1em]">
        <div className="p-5 rounded-lg w-[35%] max-lg:w-[70%] max-md:w-[90%] mx-auto">
          <div className="flex justify-center">
            <img src="/assets/success-icon.png" alt="" loading="lazy" />
          </div>
          <div className="text-[28px] max-lg:text-[22px] max-md:text-[16px] font-[600] mt-4 py-2">
            Onboarding Completed
          </div>
          <div className="text-[#667185] text-[14px] font-[500]">
            <div className="my-3 w-full mx-auto text-center">
              You have successfully completed your partner account onboarding.
              We will review and get back to you shortly.
            </div>
            <div className="flex items-center justify-center my-5 w-[30%] mx-auto">
              {/* <RoundedMdButton
                className="border bg-[#FF9D21] text-white"
                label={"Got it"}
                onClick={redirectToLogin}
              /> */}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default OnboardingCompleted;
