import React, { useState } from "react";
import CardTitleBig from "../../commons/PageTitleBig";
import CustomInputField from "../../commons/CustomInputField";
import RoundedMdButton from "../../commons/RoundedMdButton";
import { Formik, Form } from "formik";
import useSignupVendor from "../../hooks/useSignupVendor";
import CustomSnackBar from "../../commons/CustomSnackBar";
import CustomThreeDotsSpinner from "../../commons/spinners/CustomThreeDotsSpinner";
import useGoogleLogin from "../../hooks/useGoogleLogin";
import { useNavigate } from "react-router-dom";
import { encryptData } from "../../helpers/encryptor";

function SignupContent({ partnerType }) {
  const [page, setPage] = useState(1);
  const [nextClickable, setNextClickable] = useState(false);

  const { signVendorUp } = useSignupVendor();
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [isError, setIsError] = useState(false);

  const { googleLogUserIn } = useGoogleLogin();
  const navigate = useNavigate();

  return (
    <div className="flex items-center py-10">
      <div className="shadow-md rounded-lg  bg-white p-6 w-[50%] max-lg:w-[80%] max-md:w-[90%] mx-auto">
        <>
          <CardTitleBig title="Create a new account" />
          <Formik
            initialValues={{
              email: "",
              password: "",
              confirmPassword: "",
              partnerType,
              phoneNumber: "",
              firstName: "",
              lastName: "",
            }}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              signVendorUp({
                email: encryptData(values.email),
                password: encryptData(values.password),
                partnerType: encryptData(values.partnerType),
                phoneNumber: encryptData(values.phoneNumber),
                firstName: encryptData(values.firstName),
                lastName: encryptData(values.lastName),
              })
                .then((res) => {
                  navigate("/auth/registration-sent");
                  setIsError(false);
                  resetForm();
                })
                .catch((err) => {
                  setSnackBarMessage(err?.message);
                  setIsError(true);
                  setOpenSnackBar(true);
                })
                .finally(() => {
                  setSubmitting(false);
                });
            }}
            validate={(values) => {
              const errors = {};
              if (!values.email) {
                errors.email = "Email is required";
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = "Invalid email address";
              }

              if (!values.password) {
                errors.password = "Password is required";
              } else if (!/\^*[A-Z]/i.test(values.password)) {
                errors.password = "Password should contain an uppercase";
              } else if (!/\^*[a-z]/.test(values.password)) {
                errors.password = "Password should contain a lowercase";
              } else if (!/\^*[0-9]/i.test(values.password)) {
                errors.password = "Password should contain a number";
              } else if (!/\^*[!@#$%^&*]/i.test(values.password)) {
                errors.password =
                  "Password should contain at least one special character";
              } else if (values.password.length < 8) {
                errors.password = "Password should be at least 8 characters";
              }
              if (!values.confirmPassword) {
                errors.confirmPassword = "Confirm password is required";
              }

              if (values.confirmPassword !== values.password) {
                errors.confirmPassword = "Passwords must match";
              }

              if (Object.keys(errors).length == 0) {
                setNextClickable(true);
              } else {
                setNextClickable(false);
              }

              if (!values.firstName) {
                errors.firstName = "First name is required";
              }
              if (!values.lastName) {
                errors.lastName = "Last name is required";
              }
              if (!values.phoneNumber) {
                errors.phoneNumber = "Mobile number is required";
              }

              return errors;
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                {page === 1 && (
                  <>
                    <CustomInputField
                      label={"Email Address"}
                      type="email"
                      placeholder="Please enter your email address"
                      name="email"
                    />
                    <CustomInputField
                      label={"Password"}
                      type="password"
                      placeholder="Please enter your password"
                      name="password"
                    />
                    <CustomInputField
                      label={"Confirm Password"}
                      type="password"
                      placeholder="Please re-enter your password"
                      name="confirmPassword"
                    />
                    <RoundedMdButton
                      onClick={() => setPage(2)}
                      label="Next"
                      className="bg-[#FF9D21] text-white hover:bg-orange-600"
                      type="button"
                      disabled={!nextClickable}
                    />
                  </>
                )}
                {page === 2 && (
                  <>
                    <CustomInputField
                      label={"First Name"}
                      type="text"
                      placeholder="Please enter your first name"
                      name="firstName"
                      disabled={isSubmitting}
                    />
                    <CustomInputField
                      label={"Last Name"}
                      type="text"
                      placeholder="Please enter your last name"
                      name="lastName"
                      disabled={isSubmitting}
                    />
                    {/* <CustomInputField
                      label={"Business Name"}
                      type="text"
                      placeholder="Please enter your business name"
                      name="businessName"
                      disabled={isSubmitting}
                    />
                    <CustomInputField
                      label={"Location"}
                      type="text"
                      placeholder="Please select your location"
                      name="location"
                      disabled={isSubmitting}
                    /> */}
                    <CustomInputField
                      label={"Mobile Number"}
                      disabled={isSubmitting}
                      type="text"
                      placeholder="Please enter your mobile number"
                      name="phoneNumber"
                    />
                    <div className="flex justify-between gap-3">
                      <RoundedMdButton
                        label="Back"
                        onClick={() => setPage(1)}
                        disabled={isSubmitting}
                        className="bg-white text-[#FF9D21] hover:opacity-80 border"
                        type="button"
                      />
                      <RoundedMdButton
                        label={isSubmitting ? "Please wait" : "Create account"}
                        disabled={isSubmitting}
                        className="bg-[#FF9D21] text-white hover:bg-orange-600"
                        type="submit"
                        left={false}
                      >
                        {isSubmitting && (
                          <CustomThreeDotsSpinner
                            height="20"
                            width="20"
                            color="white"
                          />
                        )}
                      </RoundedMdButton>
                    </div>
                  </>
                )}
                {page === 1 && !isSubmitting && (
                  <>
                    <div className="flex gap-2 items-center justify-center">
                      <p className="text-gray-400">Already have an account? </p>
                      <p className="font-[500] text-[#FF9D21] my-5 cursor-pointer">
                        <a href="/auth/login">Log in</a>
                      </p>
                    </div>
                  </>
                )}
              </Form>
            )}
          </Formik>
        </>
      </div>
      <CustomSnackBar
        isOpen={openSnackBar}
        onClose={() => setOpenSnackBar(false)}
        message={snackBarMessage}
        isError={isError}
      />
    </div>
  );
}

export default SignupContent;
