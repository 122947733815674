import React from "react";
import { IoLogoTiktok } from "react-icons/io5";

function SocialLinks() {
  return (
    <div>
      <div className="flex gap-4 items-center">
        <a
          href={`https://twitter.com/WudifyAfrica`}
          target="_blank"
          rel="noreferrer"
        >
          <img className="cursor-pointer" src="/assets/x_icon.png" alt="" />
        </a>
        <a
          href={`https://www.facebook.com/profile.php?id=61551922751663&viewas=100000686899395`}
          target="_blank"
          rel="noreferrer"
        >
          <img
            className="cursor-pointer"
            src="/assets/facebook_icon.png"
            alt=""
            loading="lazy"
          />
        </a>
        <a
          href={`https://www.instagram.com/wudify.africa?igsh=Zm1icmpocG1uY25k`}
          target="_blank"
          rel="noreferrer"
        >
          <img
            className="cursor-pointer"
            src="/assets/instagram_icon.png"
            alt=""
            loading="lazy"
          />
        </a>
        <a
          href={`https://www.linkedin.com/company/100722299/admin/feed/posts/`}
          target="_blank"
          rel="noreferrer"
        >
          <img
            className="cursor-pointer"
            src="/assets/linkedin_icon.png"
            alt=""
            loading="lazy"
          />
        </a>
        <a
          href={`https://www.tiktok.com/@wudifyafrica?lang=en`}
          target="_blank"
          rel="noreferrer"
        >
          <div className="flex items-center justify-center w-[3em] h-[3em] bg-white rounded-full">
            <IoLogoTiktok
              className="w-[1.5em] h-[1.5em]"
              style={{ color: "black" }}
            />
          </div>
        </a>
      </div>
    </div>
  );
}

export default SocialLinks;
