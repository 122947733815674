import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { USER_QUERY_KEY } from '../constants/queryKeys'
import { deleteAccount } from "../services/auth-service";


function useDeleteAccount() {
    const navigate = useNavigate()
    const queryClient = useQueryClient();
    const { mutateAsync: deleteUserAccount, isLoading, error, data } = useMutation({
        mutationFn: (accountData) => deleteAccount(accountData),
        onSuccess: (data) => {
            queryClient.setQueryData([USER_QUERY_KEY], null)
            navigate("/auth/login")
        },
        onError: (error) => {
            // return error
        }
    })
    return {
        deleteUserAccount, isLoading, error, data
    }
}

export default useDeleteAccount