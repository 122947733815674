import { useState } from "react";
import { addDays, startOfYear } from "date-fns";
import { getDateTime } from "./../helpers/dateTime";
import CustomDatePicker from "./../commons/CustomDatePicker";

function useDatePicker(callback, format, displayText) {
  const DATE_FORMAT = format ?? "DD-MM-YYYY";
  const [showCalendar, setShowCalendar] = useState(false);
  const [state, setState] = useState([
    {
      startDate: startOfYear(new Date(), 0),
      endDate: addDays(new Date(), 0),
      key: "selection",
    },
  ]);

  function getDate(date) {
    return getDateTime(date, DATE_FORMAT);
  }

  function getText() {
    return `${getDate(state[0].startDate)} to ${getDate(state[0].endDate)}`;
  }

  const DatePickerComponent = (
    <CustomDatePicker
      callback={callback ? callback : () => {}}
      state={state}
      setState={setState}
      showCalendar={showCalendar}
      setShowCalendar={setShowCalendar}
    />
  );

  const DateDisplay = (
    <div className="border text-center mt-4 py-3 bg-white text-orange-400 text-[14px]">
      You are viewing data for{" "}
      <span className="text-textBlue">
        {displayText ? displayText : getText()}
      </span>
    </div>
  );

  return {
    startDate: getDate(state[0].startDate),
    endDate: getDate(state[0].endDate),
    DatePickerComponent,
    DateDisplay,
  };
}

export default useDatePicker;
