import React, { useLayoutEffect, useReducer, useState } from "react";
import { useLocation } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import ScrollToTop from "../commons/ScrollToTop";
import CustomSnackBar from "./CustomSnackBar";
import { infoDisplayReducer } from "./../reducers/infoDisplayReducer/index";
import { removeMessageInfo } from "../reducers/infoDisplayReducer/actions";
import useCustomInfoDisplay from "./../hooks/useCustomInfoDisplay";

function Layout({ children, bgClassName = "bg-transparent" }) {
  const { messageState, setCloseSnackBar } = useCustomInfoDisplay();

  return (
    <div className="relative">
      <ScrollToTop />
      <Header />
      <div className={`${bgClassName} lg:min-h-[100vh]`}>{children}</div>
      <Footer />

      <CustomSnackBar
        isOpen={messageState.display}
        onClose={() => setCloseSnackBar()}
        message={messageState.state}
        isError={messageState.isError}
      />
    </div>
  );
}

export default Layout;
