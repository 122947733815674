import React, { useReducer } from "react";
import PageTitleBig from "../../../commons/PageTitleBig";
import InstallerContactDetails from "./InstallerContactDetails";
import HeadingAndDetails from "../HeadingAndDetails";
import RadioItem from "../../../commons/RadioItem";
import { radioGroupHandler } from "../../../reducers/radioGroupHandler";
import { toggleRadioSelection } from "../../../reducers/radioGroupHandler/actions";

function Installer({ stepNumber, setStepNumber }) {
  const initialState = {
    radioValue: "",
  };
  const [state, dispatch] = useReducer(radioGroupHandler, initialState);

  const handleRadioChange = (value) => {
    dispatch(toggleRadioSelection(value));
  };

  return (
    <div>
      <PageTitleBig title="Installer Onboarding Form" />
      <div className="w-[65%] max-lg-[80%] max-md:w-[95%] mx-auto text-left">
        <InstallerContactDetails
          handleRadioChange={handleRadioChange}
          state={state}
          setStepNumber={setStepNumber}
        />
      </div>
    </div>
  );
}

export default Installer;
