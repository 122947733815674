import { useNavigate, useParams } from "react-router-dom";
import Layout from "../../../../commons/Layout";
import PageBreadCrumb from "../../../../commons/PageBreadCrumb";
import PageTitle from "../../../../commons/PageTitle";
import NavButton from "../../NavButton";
import CardTitle from "../../../../commons/CardTitle";
import CustomIconLabelValue from "../../../../commons/CustomIconLabelValue";
import { AiOutlineUser } from "react-icons/ai";
import { MdOutlineEmail } from "react-icons/md";
import { GiSmartphone } from "react-icons/gi";
import useUser from "../../../../hooks/useUser";
import useFetchOrderById from "../../../../hooks/useFetchOrderById";
import RadioItem from "../../../../commons/RadioItem";
import CustomLabelValue from "../../../../commons/CustomLabelValue";
import StyledSpan from "../../../../commons/StyledSpan";
import { Form, Formik } from "formik";
import CustomSelectField from "../../../../commons/CustomSelectField";
import { TfiLocationPin } from "react-icons/tfi";
import { useState } from "react";
import CustomPriceDisplay from "../../../../commons/CustomPriceDisplay";
import TimelineItem from "../../../../commons/TimelineItem";
import { PiPackageThin } from "react-icons/pi";
import useUpdateOrder from "../../../../hooks/useUpdateOrder";
import CustomTextField from "../../../../commons/CustomTextField";
import RoundedMdButton from "../../../../commons/RoundedMdButton";
import CustomThreeDotsSpinner from "../../../../commons/spinners/CustomThreeDotsSpinner";
import GeneralModal from "../../../../commons/GeneralModal";
import CustomSnackBar from "../../../../commons/CustomSnackBar";
import { capitalizeFirstLetter } from "../../../../helpers/strings";

const OrderDetailsPage = () => {
  const navigate = useNavigate();

  const params = useParams();
  const id = params.id;

  const { user } = useUser();

  const { order } = useFetchOrderById(user, id);
  const customerDetails = order?.User;
  const product = order?.Product;

  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [isError, setIsError] = useState(false);

  const { updateOrderDetails } = useUpdateOrder();

  return (
    <Layout>
      <div className="w-[85%] max-md:w-[95%] mx-auto my-6 text-left">
        <PageTitle title={"Orders"} />
        <div className="flex justify-between">
          <PageBreadCrumb
            mainText="Orders"
            subText="Order Details"
            isActiveIndex={1}
          />

          <div className="flex gap-4">
            <div>
              <NavButton
                label={"Back"}
                onClick={() => navigate(-1)}
                className="bg-orange-500 text-white"
              />
            </div>
          </div>
        </div>

        {/* Second section */}
        <div className="mt-6 grid grid-cols-4 max-lg:grid-cols-3 max-md:grid-cols-2 max-sm:grid-cols-1 gap-4 w-full">
          {/* First Card */}
          <div className="p-4 shadow-sm rounded-md w-full bg-white border border-lightGrey">
            <CardTitle title={"Customer Details"} />
            <div className="mt-3">
              <CustomIconLabelValue
                icon={
                  <AiOutlineUser className="text-[16px] max-md:text-[14px]" />
                }
                label="Name"
                value={`${customerDetails?.firstName} ${customerDetails?.lastName}`}
              />
              <CustomIconLabelValue
                icon={
                  <MdOutlineEmail className="text-[16px] max-md:text-[14px]" />
                }
                label="Email"
                value={customerDetails?.email}
              />
              <CustomIconLabelValue
                icon={
                  <GiSmartphone className="text-[16px] max-md:text-[14px]" />
                }
                label="Phone"
                value={customerDetails?.phoneNumber}
              />
            </div>
          </div>

          {/* Second Card */}
          <div className="p-4 shadow-sm rounded-md w-full bg-white border border-lightGrey">
            <CardTitle title={"Address"} />
            <div className="mt-3">
              <CustomIconLabelValue
                icon={
                  <TfiLocationPin className="text-[16px] max-md:text-[14px]" />
                }
                label="Billing Address"
                value={
                  order?.shippingAddress
                    ? JSON.parse(order?.shippingAddress)?.address
                    : "N/A"
                }
              />
              <CustomIconLabelValue
                icon={
                  <TfiLocationPin className="text-[16px] max-md:text-[14px]" />
                }
                label="Shipping Address"
                value={
                  order?.shippingAddress
                    ? JSON.parse(order?.shippingAddress)?.address
                    : "N/A"
                }
              />
            </div>
          </div>

          {/* Third Card */}
          <div className="p-4 shadow-sm rounded-md w-full bg-white border border-lightGrey">
            <CardTitle title={"Installation"} />
            <div className="mt-3">
              <RadioItem
                textStyle={"text-[12px] font-[400]"}
                padding={"p-3"}
                label="Customer requires installation"
              />
              <div className="mt-8">
                <CustomLabelValue label="Date" value={"12/12/2024"} />
              </div>
            </div>
          </div>

          {/* Fourth Card */}
          <div className="p-4 shadow-sm rounded-md w-full bg-white border border-lightGrey">
            <div className="flex justify-between items-center">
              <CardTitle className={"!mb-0"} title={"Status"} />
              <StyledSpan
                textColor={"!text-[1em] max-md:!text-[0.8em]"}
                text={capitalizeFirstLetter(order?.trackingStatus)}
              />
            </div>
            <div className="mt-3">
              <StyledSpan
                text={"Update delivery phase"}
                bgColor={"!bg-generalOrange !w-fit"}
                textColor="text-[white] py-2 !text-[1em] max-md:!text-[0.8em]"
                editable={true}
                onClick={() => setShowUpdateModal(!showUpdateModal)}
              />
            </div>
          </div>
        </div>

        {/* Third section */}
        <div className="mt-6 flex max-md:flex-col justify-between gap-4">
          <div className="shadow-sm rounded-md bg-white w-[65%] max-md:w-full border border-lightGrey">
            <div className="p-4">
              <CardTitle title="Ordered Item" />
            </div>

            <div className="">
              <div class="relative overflow-x-auto mb-8">
                <table class="w-full text-sm text-left rtl:text-right">
                  <thead class="font-[400] text-[13px] ">
                    <tr className="bg-gray-100">
                      <th scope="col" class="px-4 py-3 font-[400] text-[13px]">
                        <div className="flex items-center gap-2">
                          <span>Order No</span>
                        </div>
                      </th>
                      <th scope="col" class="px-4 py-3 font-[400] text-[13px]">
                        <div className="flex items-center gap-2">
                          <span>Product</span>
                        </div>
                      </th>
                      <th scope="col" class="px-4 py-3 font-[400] text-[13px]">
                        <div className="flex items-center gap-2">
                          <span>Quantity</span>
                        </div>
                      </th>
                      <th scope="col" class="px-4 py-3 font-[400] text-[13px]">
                        <div className="flex items-center gap-2">
                          <span>Amount</span>
                        </div>
                      </th>
                      <th scope="col" class="px-4 py-3 font-[400] text-[13px]">
                        <div className="flex items-center gap-2">
                          <span>Total</span>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="">
                      <td class="px-4 py-3 font-[400] text-[13px]">
                        <div className="flex items-center gap-2">
                          <span>#{order?.orderNumber}</span>
                        </div>
                      </td>
                      <td class="px-4 py-3 font-[400] text-[13px]">
                        <div className="flex items-center gap-2">
                          <span>{product?.name}</span>
                        </div>
                      </td>
                      <td class="px-4 py-3 font-[400] text-[13px]">
                        <div className="flex items-center gap-2">
                          <span>{order?.quantity}</span>
                        </div>
                      </td>
                      <td class="px-4 py-3 font-[400] text-[13px]">
                        <div className="flex items-center gap-2">
                          <span>
                            <CustomPriceDisplay
                              price={product?.priceAfterDiscount}
                            />
                          </span>
                        </div>
                      </td>
                      <td class="px-4 py-3 font-[400] text-[13px]">
                        <div className="flex items-center gap-2">
                          <span>
                            <CustomPriceDisplay
                              price={
                                order?.quantity &&
                                order?.Product?.priceAfterDiscount
                                  ? order?.quantity *
                                    order?.Product?.priceAfterDiscount
                                  : "N/A"
                              }
                            />
                          </span>
                        </div>
                      </td>
                    </tr>
                    <tr className="">
                      <td class="px-4 py-3 font-[400] text-[13px]">
                        <div className="flex items-center gap-2">
                          <span></span>
                        </div>
                      </td>
                      <td class="px-4 py-3 font-[400] text-[13px]">
                        <div className="flex items-center gap-2">
                          <span></span>
                        </div>
                      </td>
                      <td class="px-4 py-3 font-[400] text-[13px]">
                        <div className="flex items-center gap-2">
                          <span></span>
                        </div>
                      </td>
                      <td class="px-4 py-3 font-[400] text-[13px]">
                        <div className="flex items-center gap-2">
                          <span>Installation Fee</span>
                        </div>
                      </td>
                      <td class="px-4 py-3 font-[400] text-[13px]">
                        <div className="flex items-center gap-2">
                          <span>
                            <CustomPriceDisplay
                              price={order?.installationFee || 0}
                            />
                          </span>
                        </div>
                      </td>
                    </tr>

                    <tr className="">
                      <td class="px-4 py-3 font-[400] text-[13px]">
                        <div className="flex items-center gap-2">
                          <span></span>
                        </div>
                      </td>
                      <td class="px-4 py-3 font-[400] text-[13px]">
                        <div className="flex items-center gap-2">
                          <span></span>
                        </div>
                      </td>
                      <td class="px-4 py-3 font-[400] text-[13px]">
                        <div className="flex items-center gap-2">
                          <span></span>
                        </div>
                      </td>
                      <td class="px-4 py-3 font-[400] text-[13px]">
                        <div className="flex items-center gap-2">
                          <span>Shipping cost</span>
                        </div>
                      </td>
                      <td class="px-4 py-3 font-[400] text-[13px]">
                        <div className="flex items-center gap-2">
                          <span>
                            <CustomPriceDisplay price={order?.shipping || 0} />
                          </span>
                        </div>
                      </td>
                    </tr>
                    <tr className="">
                      <td class="px-4 py-3 font-[400] text-[13px]">
                        <div className="flex items-center gap-2">
                          <span></span>
                        </div>
                      </td>
                      <td class="px-4 py-3 font-[400] text-[13px]">
                        <div className="flex items-center gap-2">
                          <span></span>
                        </div>
                      </td>
                      <td class="px-4 py-3 font-[400] text-[13px]">
                        <div className="flex items-center gap-2">
                          <span></span>
                        </div>
                      </td>
                      <td class="px-4 py-3 font-[400] text-[13px]">
                        <div className="flex items-center gap-2">
                          <span>Total</span>
                        </div>
                      </td>
                      <td class="px-4 py-3 font-[400] text-[13px]">
                        <div className="flex items-center gap-2">
                          <span>
                            <CustomPriceDisplay price={order?.total} />
                          </span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="p-4 shadow-sm rounded-md bg-white w-[35%] max-md:w-full border border-lightGrey">
            <CardTitle title="Order Timeline" />
            <div className="mt-3"></div>
            {order?.statuses?.map((timeline, index) => {
              return (
                <TimelineItem
                  status={timeline?.status}
                  description={timeline?.description}
                  date={timeline?.updatedAt}
                  currentIndex={order?.statuses?.length}
                  showLine={order?.statuses?.length !== index + 1}
                >
                  <PiPackageThin />
                </TimelineItem>
              );
            })}
          </div>
        </div>
      </div>
      {/* Modal for status update */}
      <GeneralModal
        onClose={() => setShowUpdateModal(false)}
        showCloseButton={true}
        isOpen={showUpdateModal}
      >
        <div className="flex justify-center">
          <img src="/assets/cart-alternative-icon.png" />
        </div>
        <div className="text-black">
          <p className="font-[400] text-[14px] max-md:text-[12px] my-3">
            Update Order Delivery Phase
          </p>
          <p className="font-[500] text-[18px] max-md:text-[14px] mb-4">
            {order?.Product?.name}
          </p>
        </div>
        <div>
          <Formik
            initialValues={{
              accessToken: user?.accessToken,
              orderId: order?.id,
              deliveryPhase: order?.deliveryPhase ?? "",
              remarks: order?.vendorRemarks ?? "",
            }}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              updateOrderDetails(values)
                .then((res) => {
                  setOpenSnackBar(true);
                  setSnackBarMessage(res?.data?.message);
                  setShowUpdateModal(false);
                  setIsError(false);
                  resetForm();
                })
                .catch((err) => {
                  setSnackBarMessage(err?.message);
                  setIsError(true);
                  setOpenSnackBar(true);
                })
                .finally(() => {
                  setSubmitting(false);
                });
            }}
            validate={(values) => {
              const errors = {};
              if (!values.deliveryPhase) {
                errors.deliveryPhase = "Delivery phase is required";
              }
              if (!values.remarks) {
                errors.remarks = "Remarks is required";
              }
              return errors;
            }}
          >
            {({ isSubmitting, setFieldValue, values }) => (
              <Form>
                <CustomSelectField
                  label={"Delivery Phase"}
                  type="text"
                  name="deliveryPhase"
                  placeholder="Select delivery phase"
                  disabled={isSubmitting}
                  selectedValue={values.deliveryPhase}
                  list={["ready for pick up", "picked up"]}
                  onChange={(value) => setFieldValue("deliveryPhase", value)}
                />
                <CustomTextField
                  label={"Comments"}
                  type="text"
                  name="remarks"
                  placeholder="Please enter some remarks"
                  disabled={isSubmitting}
                />

                <div className="flex gap-3 items-center">
                  <RoundedMdButton
                    label={isSubmitting ? "Please wait" : "Cancel"}
                    className="border hover:bg-gray-100"
                    disabled={isSubmitting}
                    type="button"
                    onClick={() => setShowUpdateModal(false)}
                  ></RoundedMdButton>
                  <RoundedMdButton
                    label={isSubmitting ? "Please wait" : "Update"}
                    className="bg-[#FF9D21] text-white hover:bg-orange-600"
                    disabled={isSubmitting}
                    type="submit"
                    left={false}
                  >
                    {isSubmitting && (
                      <CustomThreeDotsSpinner
                        height="20"
                        width="20"
                        color="white"
                      />
                    )}
                  </RoundedMdButton>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </GeneralModal>

      <CustomSnackBar
        isOpen={openSnackBar}
        onClose={() => setOpenSnackBar(false)}
        message={snackBarMessage}
        isError={isError}
      />
    </Layout>
  );
};

export default OrderDetailsPage;
