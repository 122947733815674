import React, { useState } from "react";
import CustomPriceDisplay from "../../../commons/CustomPriceDisplay";
import GeneralModal from "../../../commons/GeneralModal";
import StyledSpan from "../../../commons/StyledSpan";
import { capitalizeFirstLetter } from "../../../helpers/strings";
import { Form, Formik } from "formik";
import RoundedMdButton from "../../../commons/RoundedMdButton";
import CustomThreeDotsSpinner from "../../../commons/spinners/CustomThreeDotsSpinner";
import CustomSelectField from "../../../commons/CustomSelectField";
import CustomTextField from "../../../commons/CustomTextField";
import useUser from "../../../hooks/useUser";
import useUpdateOrder from "./../../../hooks/useUpdateOrder";
import CustomSnackBar from "../../../commons/CustomSnackBar";

function PaymentRowItem({ order }) {
 

  return (
    <>
      <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
        <th
          scope="row"
          class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
        >
          <div className="flex items-center gap-6">
            <div>
              <p className="text-[13px] text-[#667085] font-[400] mt-1">
                #{order?.orderNumber}
              </p>
            </div>
          </div>
        </th>
        <td class="px-6 py-4 text-[14px] font-[500]">{order?.Product?.name}</td>
        <td class="px-6 py-4 text-[14px] font-[500]">{order?.quantity}</td>
        <td class="px-6 py-4 text-[14px] font-[500]">
          <CustomPriceDisplay price={order?.total} />
        </td>
        <td class="px-6 py-4 text-[13px]">{order?.User?.id}</td>
        <td class="px-6 py-4">
          {order?.trackingStatus?.toLowerCase().includes("completed") && (
            <StyledSpan
              text={capitalizeFirstLetter(order?.trackingStatus)}
              bgColor={"bg-[#ECFDF3]"}
              textColor="text-[#027A48]"
            />
          )}
          {order?.trackingStatus?.toLowerCase().includes("cancel") && (
            <StyledSpan
              text={capitalizeFirstLetter(order?.trackingStatus)}
              bgColor={"bg-[#FEF3F2]"}
              textColor="text-[#B42318]"
            />
          )}
          {order?.trackingStatus?.toLowerCase().includes("progress") && (
            <StyledSpan
              text={capitalizeFirstLetter(order?.trackingStatus)}
              bgColor={"bg-[#FFFAEB]"}
              textColor="text-[#B54708]"
            />
          )}
        </td>
      </tr>

    </>
  );
}

export default PaymentRowItem;
