import { useMutation, useQueryClient } from '@tanstack/react-query'
import { USER_QUERY_KEY } from '../constants/queryKeys';
import { verifyEmail } from "../services/auth-service";

function useVerifyEmail() {
    const queryClient = useQueryClient();
    const { mutateAsync: verifyUserEmail, isLoading, error, data } = useMutation({
        mutationFn: (verifyData) => verifyEmail(verifyData),
        onSuccess: (data) => {
            // queryClient.setQueryData([USER_QUERY_KEY], data?.data?.data)
        },
        onError: (error) => {
            // return error
        }
    })
    return {
        verifyUserEmail, isLoading, error, data
    }
}

export default useVerifyEmail